import apiWorker from '../apiWorker'

const contacts = {
    
    search          : (company_id, data)                =>  apiWorker('post',  `/v1/admin/${company_id}/contacts/`, data),
    findById        : (company_id, contact_id, hideErr) =>  apiWorker('get',   `/v1/admin/${company_id}/contacts/${contact_id}`, null, hideErr),
    setEmployment   : (data)                =>  apiWorker('post', `/v1/api/contacts/set_employment/${data.contact_id}/${data.company_id}/${data.access_code}`, data),

}

export default contacts;