

import { useEffect, useState, useCallback } from "react";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import _events from '_functions/events'

import Circle from 'components/markup/loading/Circle'
import reactHTMLParser from 'react-html-parser'

import { Alert, Card, CardBody } from "reactstrap";
import moment from 'moment';

const limit = 25;

const ContactsTimeline = ({contact, selected_company}) => {

    const [state, setState] = useState({
        events: null,
        total_documents: 0,
    })

    const fetchEvents = useCallback(async () => {

        if(state.events && state.events.length === state.total_documents) return;

        const skip = state.events ? state.events.length : 0;
        const events = await _events.find(selected_company._id, `?filter=contact__${contact._id}&limit=${limit}&skip=${skip}&sort=created_at__desc`);
        
        if(events.data) {
            const oldEvents = state.events ? [...state.events] : [];
            const allEvents = oldEvents.concat(events.data.documents);
            setState({events: allEvents, total_documents: events.data.total_documents})
        }

    }, [selected_company._id, contact._id, state.total_documents, state.events])

    useEffect(() => {

        const fetchInitialEvents = async () => {
            const events = await _events.find(selected_company._id, `?filter=contact__${contact._id}&limit=${limit}&skip=0&sort=created_at__desc`);
            if(events.data) {
                setState({events: events.data.documents, total_documents: events.data.total_documents})
            }
        }

        fetchInitialEvents();

    }, [contact._id, selected_company._id])

    const { events, total_documents } = state;

    if(!events) return <Circle />
    if(!events.length) return (
        <Card>
            <CardBody>
                <Alert color="warning" className="mb-0">There are no events to show for this contact.</Alert>
            </CardBody>
        </Card>
    )

    return (

        <Card>
            <CardBody>
                <VerticalTimeline layout="1-column-left" >
                    {events.map(event => (
                        <VerticalTimelineElement
                        visible={true}
                            key={event._id}
                            className={`vertical-timeline-element--work type-${event.type}`}
                            date={moment.unix(event.created_at).format('MM/DD/YY h:mm A')}
                            icon={
                                event.type === 'user' ? (
                                    <i className="fas fa-user " />
                                ) : event.type === 'system' ? (
                                    <i className="fas fa-database " />
                                ) : event.type === 'action' ? (
                                    <i className="fas fa-exclamation " />
                                ) : event.type === 'info' ? (
                                    <i className="fas fa-info " />
                                ) : (
                                    <i className="fas fa-check " />
                                ) 
                            }
                        >
                            <h3 className="vertical-timeline-element-title text-capitalize">{event.type} Event</h3>
                            <p className="">{reactHTMLParser(event.body)}</p>
                        </VerticalTimelineElement>
                    ))}
                    
                    {events.length !== total_documents ? (
                        <VerticalTimelineElement
                            className={`vertical-timeline-element--work type-load-more`}
                            iconStyle={{cursor: 'pointer'}}
                            icon={<i className="fas fa-plus-square " />}
                            iconOnClick={fetchEvents}
                        />
                    ) : (
                        <VerticalTimelineElement
                            className={`vertical-timeline-element--work type-info`}
                            date={moment.unix(contact.created_at).format('MM/DD/YY h:mm A')}
                            icon={<i className="fas fa-info " />}
                        >
                            <h3 className="vertical-timeline-element-title text-capitalize">Info Event</h3>
                            <p className="">This is the start of events for{' '}
                                {contact.display_name ? contact.display_name : contact.email ? contact.email : contact.phone ? contact.phone : '-'}
                            </p>
                        </VerticalTimelineElement>
                    )}

                </VerticalTimeline>
            </CardBody>
        </Card>
  
    );

}

export default ContactsTimeline;