import { connect } from 'react-redux';
import { useEffect, useState, useCallback } from "react";
import { Helmet } from 'react-helmet';

import { Card, Container, Alert, Row, Col } from "reactstrap";

import { formatCredits } from 'utils/currency';

import Circle from 'components/markup/loading/Circle'
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'

import _analytics from '_functions/analytics'

import AnalyticsSidebar from '../_components/Sidebar'

const AnalyticsCreditEvents = ({analytics_query_date, analytics_company}) => {

    const [ data, setData ] = useState(null)
    const [ err, setErr ] = useState(false)

    const fetchData = useCallback(async (_date, company) => {
        setData(null)
        const data = await _analytics.creditEvents(`?timeframe=${_date}&company=${company}`);
        if(data.data) {
            setData(data.data)
        } else {
            setErr('true')
        }
    }, [])

    const onQuery = useCallback(() => {
        fetchData(analytics_query_date, analytics_company)
    }, [fetchData, analytics_query_date, analytics_company])

    useEffect(() => {
        if(!data) fetchData(analytics_query_date, analytics_company)
    }, [fetchData, analytics_query_date, analytics_company, data])


    if(err) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }

    return (
        <Container fluid>
    
            <Helmet>
                <title>{`Analytics`}</title>
                <meta name="description" content="Analytics" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Analytics</span>}
                breadcrumb_1={'Credits Spent'}
                icon="fas fa-chart-pie"
            />
    
            <Card className="card-color card-primary table-fixed">

                <Row className="archk-internal-sidebar">

                    <AnalyticsSidebar 
                        onQuery={onQuery}
                        data={data}
                        show={{
                            company: true
                        }}
                    />

                    <Col className="border-left">
                      
                        {!data ? (
                            <div className="py-6"><Circle /></div>
                        ) : (
                            <div className="table-responsive border-bottom">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Company</th>
                                            <th className="text-right">Credits Spent</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.results.length ? data.results.map((d, i) => (
                                            <tr key={i}>
                                                <td>{d.company ? d.company.name : '-'}</td>
                                                <td className="text-right">{formatCredits(d.total)}</td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td>No Data To Show</td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </Col>

                </Row>
               
            </Card>
        </Container>
    )

}


const mapStateToProps = state => {
	return {
	    analytics_company: state.system.analytics_company,
	    analytics_query_date: state.system.analytics_query_date,
	};
};

export default connect(mapStateToProps, '')(AnalyticsCreditEvents);