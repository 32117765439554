/*
Documentation

This file holds all the socket events to listen for within this system
this should correspond exactly to /server/sockets/socketEvents.js in the server
code base of this project

*/

export const JOIN_COMPANY_ROOM        = 'JOIN_COMPANY_ROOM';
export const LEAVE_ROOM               = 'LEAVE_ROOM';

export const CONTACT_CREATED          = 'CONTACT_CREATED';
export const CONTACT_UPDATED          = 'CONTACT_UPDATED';
export const CONTACT_DELETED          = 'CONTACT_DELETED';

export const COMPANY_UPDATED          = 'COMPANY_UPDATED';

export const LOGOUT                   = 'LOGOUT';