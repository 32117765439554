import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from "reactstrap";

const ModalPreviewImage = ({showModal, toggleModal, doc}) => (
    <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={toggleModal}
        size="lg"
    >
        <div className="modal-header">
            <h5 className="modal-title">{doc.name}</h5>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
            >
                <span aria-hidden={true}>×</span>
            </button>
        </div>

        <div className="modal-body text-center">

            <img style={{maxWidth: '100%'}} alt={doc.name} src={doc.url} />

        </div>

        <div className="modal-footer">
            <button onClick={toggleModal} className="btn btn-warning" >
                Close
            </button>
        </div>

    </Modal>
)


ModalPreviewImage.propTypes = {
    showModal   : PropTypes.bool.isRequired,
    toggleModal : PropTypes.func.isRequired,
    doc         : PropTypes.object.isRequired,
}

export default ModalPreviewImage
