import { useCallback, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { Card, Container, Alert, Modal } from "reactstrap";

import Table from 'components/functional/tables/Standard';
import React from "react";
import { Link } from 'react-router-dom';
import _flagged from '_functions/flagged';

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'
import { toggleAlertBS, toggleStandardLoader } from "store/functions/system/system";

import { getDownloadName } from 'utils/documents'
import ConfirmationModal from 'components/functional/modals/Confirmation'

import ImageRender from 'components/functional/images/ImageRenderer'

import ModalToggler from 'components/functional/modals/Toggler'

import moment from 'moment';

const FlagModal = ({showModal, toggleModal, flags}) => (
    <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={toggleModal}
        size="lg"
    >

        <div className="modal-header">
            <h5 className="modal-title">Document Flags</h5>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
            >
                <span aria-hidden={true}>×</span>
            </button>
        </div>

        <div className="modal-body">
            {flags.map((flag, i) => i === 0 ? flag : ', ' + flag)}
        </div>
    </Modal>
)

const FlaggedDocuments = () => {

    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: null, total_documents: 0 })
    const [ error, setError ] = useState(false)
    
    const [ docToApprove, setDocToApprove ] = useState({})
    const [ docToReject, setDocToReject ] = useState({})
    const [ refreshing, setRefreshing ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback((params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }

        const query = await _flagged.search({ ...params,  isCSV })
        if(query.data) {
            return resolve(query.data)
        } else {
            setError(true)
        }
    }), [sizePerPage])

    const onApprove = useCallback(async (document) => {
        toggleStandardLoader(true)
        const approved = await _flagged.approve(document._id)
        if(approved.data) {
            toggleAlertBS(false, `Document has been approved.`)
            setRefreshing(true)
            await fetchData();
            setRefreshing(false)
            setDocToApprove({})
        } else {
            toggleAlertBS(true, `Document was not approved, please try again later.`)
        }
        toggleStandardLoader(false)
    }, [fetchData])
    
    const onReject = useCallback(async (document) => {
        toggleStandardLoader(true)
        const rejected = await _flagged.reject(document._id)
        if(rejected.data) {
            toggleAlertBS(false, `Document has been rejected.`)
            setRefreshing(true)
            await fetchData();
            setRefreshing(false)
            setDocToReject({})
        } else {
            toggleAlertBS(true, `Document was not rejected, please try again later.`)
        }
        toggleStandardLoader(false)
    }, [fetchData])

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {
            dataField: "reference_number",
            text: "#"
        },
        {
            dataField: "name",
            text: "Name",
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )

                let message = row.messages[row.messages.length -1];
                if(message && message.length > 40) message = message.slice(0,40) + '...'

                return (

                    <div className="text-sm">
                        <span className="cursor-pointer">
                            <ImageRender doc={row} approve={() => setDocToApprove(row)} reject={() => setDocToReject(row)}>
                                {getDownloadName(row, row.contact)}
                            </ImageRender>
                        </span>
        
                        <div style={{maxWidth: 200, whiteSpace: 'pre-lne'}} className="text-sm text-muted">
                            <i className="fas fa-calendar mr-2 text-info " /> {moment.unix(row.created_at).format('MMM Do, YYYY - h:mm A')}
                        </div>
                    </div>
                )
            }
        },
        {
            dataField: "company",
            text: "Type",
            formatter: (cell, row) => (
                <div>
                    <div>{row.name === 'no data' ? '' :row.company ? row.company.name : '-'}</div>
                    <div>
                        {row.pinwheel_account ? 'pinwheel' : row.yodlee_account_id ? 'yodlee' : row.mx_account_guid ? 'mx' : '-'}
                    </div>
                </div>
            )
        },
        {
            dataField: "flags",
            text: "Flags",
            formatter: (cell, row) => (
                !row.flags ? null : 
                <div className="text-sm" style={{whiteSpace: 'pre-line'}}>
                    {row.flags.map((flag, i) => i === 0 ? flag : 
                    i === 6 ? (
                        <ModalToggler component={FlagModal} flags={row.flags}> 
                            <span className="ml-3 text-warning cursor-pointer font-weight-bold">View All</span>
                        </ModalToggler> 
                    ) : 
                    i > 6 ? null:  
                    ', ' + flag)}
                </div>
            )
        },
        {
            dataField: "_id",
            text: "Preview",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => (
                <div className="text-right">
                    {row.url ? (
                        <ImageRender doc={row} approve={() => setDocToApprove(row)} reject={() => setDocToReject(row)}>
                            <button className="btn btn-sm btn-outline-success">Preview</button>
                        </ImageRender>
                        
                    ) : '-'}
                </div>
            )
        },
    ]

    if(error) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }

    return (
        <Container fluid>
    
            <Helmet>
                <title>{`Flagged Documents`}</title>
                <meta name="description" content="Flagged Documents" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Flagged</span>}
                breadcrumb_1="Documents"
                icon="fas fa-file text-info"
                actionComponent={(
                    <Link to={`/dashboard/home`} className="btn btn-outline-warning">
                        <i className="fas fa-arrow-left " /> Home
                    </Link>
                )}
            />

            <Card className="card-color card-primary">    
                {refreshing ? null : (
                    <Table
                        placeholder="Document Name"
                        hideSearch={true}
                        query={query}
                        columns={columns}
                        data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                        totalDocuments={tableData.total_documents}
                        sizePerPage={sizePerPage}
                        onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                    />
                )}
            </Card>
    
            <ConfirmationModal 
                prevButtonText="Cancel"
                nextButtonText="Approve"
                showModal={docToApprove._id ? true : false}
                toggleModal={() => setDocToApprove({})}
                title="Approve Document"
                body={(
                    <span>
                        Are you sure you wish to <b>Approve</b> this document? It will be available for download by its company immediately.
                    </span>
                )}
                onConfirmation={() => onApprove(docToApprove)}
            />

            <ConfirmationModal 
                prevButtonText="Cancel"
                nextButtonText="Reject"
                showModal={docToReject._id ? true : false}
                toggleModal={() => setDocToReject({})}
                title="Reject Document"
                body={(
                    <span>
                        Are you sure you wish to <b>REJECT</b> this document? It will be marked as rejected and unavailable for download.
                    </span>
                )}
                onConfirmation={() => onReject(docToReject)}
            />
        </Container>
    )

}

const mapStateToProps = state => {
	return {
        selected_company: state.company.selected_company,
	};
};


export default connect(mapStateToProps, '')(FlaggedDocuments);