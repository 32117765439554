/*
Documentation

all dispatch actions should be declared in this file and used

this prevents mis-typing action names as it will through an error
by either the browser or IDE

*/


//system reducer
export const TOGGLE_SYSTEM_ALERT                  = 'TOGGLE_SYSTEM_ALERT';
export const TOGGLE_SYSTEM_LOADER                 = 'TOGGLE_SYSTEM_LOADER';
export const TOGGLE_STANDARD_LOADER               = 'TOGGLE_STANDARD_LOADER';
export const TOGGLE_SYSTEM_ALERT_BS               = 'TOGGLE_SYSTEM_ALERT_BS'

//auth reducer
export const SET_ANALYTICS_QUERY_DATE             = 'SET_ANALYTICS_QUERY_DATE';
export const SET_ANALYTICS_COMPANY                = 'SET_ANALYTICS_COMPANY';

export const SET_LOADED                           = 'SET_LOADED';
export const SET_VIEWING_USER                     = 'SET_VIEWING_USER';
export const SET_COMPANY                          = 'SET_COMPANY';
export const SET_COMPANY_LIST                     = 'SET_COMPANY_LIST';
export const SET_SELECTED_COMPANY                 = 'SET_SELECTED_COMPANY';
export const SET_SELECTED_COMPANY_REGISTRATION    = 'SET_SELECTED_COMPANY_REGISTRATION';

export const SET_NAVBAR_STATE                     = 'SET_NAVBAR_STATE';

export const SET_DEVICE                           = 'SET_DEVICE';

