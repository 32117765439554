import { connect } from 'react-redux';
import { useEffect, useState, useCallback } from "react";
import { Helmet } from 'react-helmet';

import { Card, Container, Alert, Row, Col } from "reactstrap";

import Circle from 'components/markup/loading/Circle'
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'

import _internal_costs from '_functions/internal_costs'
import { formatMoney } from 'utils/currency';

import AnalyticsSidebar from '../_components/Sidebar'

const AnalyticsDocuments = ({analytics_query_date, selected_company}) => {

    const [ data, setData ] = useState(null)
    const [ err, setErr ] = useState(false)

    const fetchData = useCallback(async (_date, _dateEnd) => {
        setData(null)       

        const data = await _internal_costs.byCompany(selected_company._id, `?timeframe=${_date}`);
        if(data.data) {
            setData(data.data)
        } else {
            setErr('true')
        }
    }, [selected_company._id])

    const onQuery = useCallback(() => {
        fetchData(analytics_query_date)
    }, [fetchData, analytics_query_date])

    useEffect(() => {
        if(!data) fetchData(analytics_query_date)
    }, [fetchData, analytics_query_date, data])

    const keyIsHidden = useCallback((key) => {
        if(
            key === '_id' || 
            key === 'total' || 
            key === 'total_cost' || 
            key === 'pw_monthly' || 
            key === 'mx_monthly' || 
            key === 'yodlee_monthly' || 
            key.includes('total')
        ) return true
        return false;
    }, [])

    if(err) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }

    return (
        <Container fluid>
    
            <Helmet>
                <title>{`Analytics`}</title>
                <meta name="description" content="Analytics" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Analytics</span>}
                breadcrumb_1={'Internal Costs'}
                icon="fas fa-chart-pie"
            />
    
            <Card className="card-color card-primary table-fixed">

                <Row className="archk-internal-sidebar">

                    <AnalyticsSidebar 
                        onQuery={onQuery}
                        data={data}
                    />

                    <Col className="border-left">

                        {!data ? (
                            <div className="py-6"><Circle /></div>
                        ) : (
                            <div className="table-responsive border-bottom">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th className="text-right">Amount</th>
                                            <th className="text-right">Cost</th>
                                            <th className="text-right">Percent</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data ? (
                                            <>
                                            {Object.keys(data).map(key => (
                                                keyIsHidden(key) ? null :
                                                <tr key={key}>
                                                    <td className="text-capitalize" style={{width: 200}}>{key.replace(/_/g, ' ').replace(' 3', '')}</td>
                                                    <td className="text-right">{data[key]}</td>
                                                    <td className="text-right">{formatMoney(data[key + '_total'])}</td>
                                                    <td className="text-right">{((data[key + '_total'] / data.total_cost) * 100).toFixed(2)}%</td>
                                                </tr>
                                            ))}

                                            <tr>
                                                <td className="text-uppercase" style={{width: 200}}>TOTAL</td>
                                                <td className="text-right">{data.total}</td>
                                                <td className="text-right">{formatMoney(data.total_cost)}</td>
                                                <td className="text-right">100%</td>
                                            </tr>

                                            </>
                                        ) : (
                                            <tr>
                                                <td>No Data To Show</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </Col>

                </Row>
               
            </Card>
        </Container>
    )

}


const mapStateToProps = state => {
	return {
	    analytics_query_date: state.system.analytics_query_date,
	};
};

export default connect(mapStateToProps, '')(AnalyticsDocuments);