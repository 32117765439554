/*
Documentation

requires that selected company is set.

If its not set we look for props.match.params.company_id in the url bar
if found we fetch and set the company, if not we redirect to the dashboard

*/

import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { useState, useEffect } from 'react';
import { setSelectedCompany } from "store/functions/company/company";
import _companies from '_functions/companies';

const SelectedCompanyRoute = (props) => {

    const { selected_company } = props;
    const { company_id } = props.match.params;

    const companyMatches = selected_company && selected_company._id === company_id

    const [loaded, setLoaded] = useState(companyMatches);
    const [redirect, setRedirect] = useState(false);



    useEffect(() => {

        const setCompany = async () => {
            const company = await _companies.findById(company_id, true);
            if(company.data) {
                setSelectedCompany(company.data)
                setLoaded(true);
            } else {
                setRedirect('/dashboard/home')
            }
        }

        // if selected company matches navbar we pass
        if(!companyMatches) {
            setLoaded(false);
            setCompany()
        }

    }, [selected_company._id, company_id, companyMatches])

    if(redirect) return <Redirect to={redirect} />
    if(!loaded) return <div />

    return (
        <div className="selected-company-route">
            <props.children {...props} />
        </div>
    )

}


const mapStateToProps = state => {
	return {
	    selected_company: state.company.selected_company,
	};
};

export default connect(mapStateToProps, '')(SelectedCompanyRoute);