/*
Documentation

compile and export all reducers for this system

*/

import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import system from './reducers/system/system';
import auth from './reducers/auth/auth';
import device from './reducers/device/device';
import company from './reducers/company/company';
import load from './reducers/load/load';

const rootReducer = combineReducers({
    auth,
    system,
    device,
    company,
    load,
})

const store = createStore(rootReducer, applyMiddleware(thunk));;
export default store
