import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { Card, Container, Row, Col, CardBody } from "reactstrap";

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'

import formatText from 'utils/formatText';
import { formatCredits } from 'utils/currency';

const CompaniesView = ({match, selected_company}) => {
 
    return (
        <Container fluid>
    
            <Helmet>
                <title>{`All Companies`}</title>
                <meta name="description" content="Companies" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Companies</span>}
                breadcrumb_2={selected_company.name}
                icon="fas fa-home"
            />

            <Card>
                <CardBody>
                    <Row>
                        <Col xl={9} lg={8}>
                            <div className="table-responsive">
                                <table className="table border-left border-right border-bottom">
                                    <thead>
                                        <tr>
                                            <th>Contact Info</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Name</td>
                                            <td>{selected_company.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Phone</td>
                                            <td>{formatText(selected_company.phone).phone()}</td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td>{selected_company.email}</td>
                                        </tr>
                                        <tr>
                                            <td>Email 2</td>
                                            <td>{selected_company.email_2}</td>
                                        </tr>
                                        <tr>
                                            <td>Email 3</td>
                                            <td>{selected_company.email_3}</td>
                                        </tr>
                                    </tbody>
                                    <thead>
                                        <tr>
                                            <th>Address</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Address Line 1</td>
                                            <td>{selected_company.billing_address_line_1}</td>
                                        </tr>
                                        <tr>
                                            <td>Address Line 2</td>
                                            <td>{selected_company.billing_address_line_2}</td>
                                        </tr>
                                        <tr>
                                            <td>City</td>
                                            <td>{selected_company.billing_city}</td>
                                        </tr>
                                        <tr>
                                            <td>State</td>
                                            <td>{selected_company.billing_state}</td>
                                        </tr>
                                        <tr>
                                            <td>Postal Code</td>
                                            <td>{selected_company.billing_postal_code}</td>
                                        </tr>
                                    </tbody>
                                    <thead>
                                        <tr>
                                            <th>Credits</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Billing</td>
                                            <td>{formatCredits(selected_company.credits)}</td>
                                        </tr>
                                        <tr>
                                            <td>Reloader Threshold</td>
                                            <td>{formatCredits(selected_company.reloader_threshold)}</td>
                                        </tr>
                                        <tr>
                                            <td>Reloader Package</td>
                                            <td>{selected_company.reloader_package ? selected_company.reloader_package.name : '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>Reloader Last Try</td>
                                            <td>
                                                {selected_company.reloader_package && selected_company.reloader_package.reloader_last_tried ? 
                                                    moment.unix(selected_company.reloader_package.reloader_last_tried).format('MM/DD/YYYY - h:mma') : 
                                                    '-'
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                    <thead>
                                        <tr>
                                            <th>API</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>API Key</td>
                                            <td>{selected_company.api_key}</td>
                                        </tr>
                                        <tr>
                                            <td>API Last 6</td>
                                            <td>{selected_company.api_secret_last_six}</td>
                                        </tr>
                                    </tbody>
                                    <thead>
                                        <tr>
                                            <th>Settings</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Accept Employment</td>
                                            <td>{selected_company.accept_employment ? 'true' : 'false'}</td>
                                        </tr>
                                        <tr>
                                            <td>Accept Financial</td>
                                            <td>{selected_company.accept_financial ? 'true' : 'false'}</td>
                                        </tr>
                                        <tr>
                                            <td>Accept Statements</td>
                                            <td>{selected_company.accept_real_statements ? 'true' : 'false'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                        <Col xl={3} lg={4}>

                            <Link to={`/dashboard/${selected_company._id}/contacts`}>
                                <Card className="bg-secondary m-4 mb-5">
                                    <CardBody className="text-center py-5">
                                        <h3 ><i className="fas fa-users text-info" /> Contacts</h3>
                                    </CardBody>
                                </Card>
                            </Link>

                            <Link to={`/dashboard/${selected_company._id}/documents`}>
                                <Card className="bg-secondary m-4 mb-5">
                                    <CardBody className="text-center py-5">
                                        <h3 ><i className="fas fa-file mr-2 text-warning" /> Documents</h3>
                                    </CardBody>
                                </Card>
                            </Link>

                            <Link to={`/dashboard/${selected_company._id}/credit_events`}>
                                <Card className="bg-secondary m-4 mb-5">
                                    <CardBody className="text-center py-5">
                                        <h3 ><i className="fas fa-calendar mr-2 text-purple" /> Credit Events</h3>
                                    </CardBody>
                                </Card>
                            </Link>

                            <Link to={`/dashboard/${selected_company._id}/credit_purchases`}>
                                <Card className="bg-secondary m-4 mb-5">
                                    <CardBody className="text-center py-5">
                                        <h3 ><i className="fas fa-chart-line mr-2 text-success" /> Purchases</h3>
                                    </CardBody>
                                </Card>
                            </Link>

                            <Link to={`/dashboard/${selected_company._id}/analytics/internal_costs`}>
                                <Card className="bg-secondary m-4 mb-5">
                                    <CardBody className="text-center py-5">
                                        <h3 ><i className="fas fa-dollar-sign mr-2 text-success" /> Costs</h3>
                                    </CardBody>
                                </Card>
                            </Link>

                            <Link to={`/dashboard/${selected_company._id}/update`}>
                                <Card className="bg-secondary m-4 mb-5">
                                    <CardBody className="text-center py-5">
                                        <h3 ><i className="fas fa-home text-info" /> Update</h3>
                                    </CardBody>
                                </Card>
                            </Link>
                            
                        </Col>
                    </Row>
                </CardBody>
            </Card>
    
           
    
        </Container>
    )

}

const mapStateToProps = state => {
	return {
	    device: state.device,
        selected_company: state.company.selected_company,

	};
};

export default connect(mapStateToProps, '')(CompaniesView);
