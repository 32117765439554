import { connect } from 'react-redux';

import { Row, Col, Card, CardHeader, Badge, CardTitle } from "reactstrap";

import formatText from 'utils/formatText';
import moment from 'moment';

import TableDocuments from 'views/dashboard/pages/_components/Tables/Documents';

const ContactsOverview = ({contact, documents, device}) => (

    <Card>

        <CardHeader>
            <Row>
                <Col xs={7} className="align-self-center">
                    <CardTitle className="mb-0"><i className="fas fa-lock mr-4 text-warning " />Portal Access Code:</CardTitle>
                </Col>
                <Col xs={5} className="align-self-center text-right">
                    {contact.access_code}
                </Col>
            </Row>
        </CardHeader>
        <CardHeader>
            <Row>
                <Col xs={7} className="align-self-center">
                    <CardTitle className="mb-0"><i className="fas fa-battery-full mr-2" /> Current Report Status</CardTitle>
                </Col>
                <Col xs={5} className="align-self-center text-right">
                    {contact.report_status === 'null' ? '-' :
                    contact.report_status === 'requested'   ? <Badge color="warning" className={device.isMD ? 'text-sm' : ''}>Requested</Badge> : 
                    contact.report_status === 'in progress' ? <Badge color="info" className={device.isMD ? 'text-sm' : ''}>In Progress</Badge> : 
                    contact.report_status === 'complete'    ? <Badge color="success" className={device.isMD ? 'text-sm' : ''}>COMPLETE</Badge>
                    : '-'}
                </Col>
            </Row>
        </CardHeader>

        <CardHeader className="p-0">
            <div className="table-responsive">

                {device.info.isMD ? (
                    <table className="table">

                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Reports</th>
                            <th className="text-right">Created</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>

                            <td className="text-capitalize td-pre">
                                {contact.display_name ? (
                                    <div>
                                        <i className="fas fa-user mr-2 text-success " /> 
                                        {contact.display_name}
                                    </div>
                                ) : (
                                    <span className="text-warning font-weight-bold">Name Not Given</span>
                                )}
                            </td>

                            <td className="td-pre">
                                <i className="fas fa-envelope mr-2 text-success " /> 
                                {contact.email ? contact.email : '-'}
                            </td>

                            <td className="td-pre">
                                <i className="fas fa-mobile mr-2 text-success " /> 
                                {contact.phone ? formatText(contact.phone).phone() : '-'}
                            </td>

                            <td className="td-pre">{documents.length}</td>

                            <td className="text-right td-pre">
                                {moment.unix(contact.created_at).format('MM/DD/YYYY h:mm A')}
                            </td>

                        </tr>
                    </tbody>

                </table>
                ) : (
                <table className="table">
                    <tbody>
                        
                        <tr>
                            <td style={styles.mobileTD}>Name</td>
                            <td className="text-capitalize td-pre">
                                {contact.display_name ? (
                                    <div>
                                        <i className="fas fa-user mr-2 text-success " /> 
                                        {contact.display_name}
                                    </div>
                                ) : (
                                    <span className="text-warning font-weight-bold">Name Not Given</span>
                                )}
                            </td>
                        </tr>

                        <tr>
                            <td style={styles.mobileTD}>Email</td>
                            <td className="td-pre">
                                <i className="fas fa-envelope mr-2 text-success " /> 
                                {contact.email ? contact.email : '-'}
                            </td>
                        </tr>

                        <tr>
                            <td style={styles.mobileTD}>Phone</td>
                            <td className="td-pre">
                                <i className="fas fa-mobile mr-2 text-success " /> 
                                {contact.phone ? formatText(contact.phone).phone() : '-'}
                            </td>
                        </tr>

                        <tr>
                            <td style={styles.mobileTD}>Document</td>
                            <td className="td-pre">{documents.length}</td>
                        </tr>

                        <tr>
                            <td style={styles.mobileTD}>Created</td>
                            <td className="text-right td-pre">
                                {moment.unix(contact.created_at).format('MM/DD/YYYY h:mm A')}
                            </td>
                        </tr>

                    </tbody>

                </table>
                )}
                
            </div>
        </CardHeader>
  
        <CardHeader className="p-0">
            <TableDocuments documents={documents} />
        </CardHeader>

    </Card>

);

const styles = {
    mobileTD: {
        width: 100,
        maxWidth: 100
    }
}


const mapStateToProps = state => {
	return {
	    device: state.device,
	};
};

export default connect(mapStateToProps, '')(ContactsOverview);