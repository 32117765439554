import { useState } from "react";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Card, Container, Alert } from "reactstrap";

import Table from 'components/functional/tables/Standard';
import React from "react";
import _credit_events from '_functions/credit_events';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'
import { formatMoney, formatCredits } from 'utils/currency';

import moment from 'moment';

import { connect } from 'react-redux';

const ContactsAll = ({device, selected_company}) => {

    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ error, setError ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        const query = await _credit_events.search(selected_company._id, { ...params, isCSV })
        
        if(query.data) {
            return resolve(query.data)
        } else {
            setError(true)
        }
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

  
    const columns = device.info.isMD ? [
        {
            dataField: "_id",
            text: "Contact(s)",
            formatter: (cell, row) => (
                row.contacts.map(c => (
                    <div>{c.display_name}</div>
                ))
            ),
        },
        {
            dataField: "type",
            text: "Type",
            headerStyle: {width: 240},
            formatter: (cell, row) => (
                <span className="text-capitalize">{row.type.replace(/_/g, ' ')}</span>
            )
        },

        {
            dataField: "credits",
            text: "Credits",
            formatter: (cell, row) => (
                formatCredits(row.credits)
            )
        },
       
        {
            dataField: "joint",
            text: "Joint",
            formatter: (cell, row) => (
                row.joint ? <i className="fas fa-check mr-2 " /> : '-'
            )
        },
        {
            dataField: "date",
            text: "Date",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => (
                <div className="text-right">
                    {moment(row.date).format('MM/DD/YYYY h:mm A')}
                </div>
            )
        },

    ] : [
        {
            dataField: "contacts",
            text: "Contact(s)",
            formatter: (cell, row) => (
                row.contacts.map(c => (
                    <div>{c.display_name}</div>
                ))
            ),
        },
        {
            dataField: "credits",
            text: "Credits",
            formatter: (cell, row) => (
                formatCredits(row.credits)
            )
        },
        {
            dataField: "usd_amount",
            text: "USD",
            formatter: (cell, row) => (
                formatMoney(row.usd_amount)
            )
        },
        {
            dataField: "type",
            text: "Type",
            formatter: (cell, row) => (
                <span className="text-capitalize">{row.type.replace(/_/g, ' ')}</span>
            )
        },

        {
            dataField: "joint",
            text: "Joint",
            formatter: (cell, row) => (
                row.join ? <i className="fas fa-check mr-2 " /> : '-'
            )
        },
        {
            dataField: "date",
            text: "Date",
            formatter: (cell, row) => (
                moment(row.date).format('MM/DD/YYYY h:mm A')
            )
        },
        
    ]

    if(error) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }

    return (
        <Container fluid>
    
            <Helmet>
                <title>{`Credit Events`}</title>
                <meta name="description" content="Credit Events" />
            </Helmet>

             <DashHeaderOpen 
                title={<span>Companies</span>}
                breadcrumb_1={<Link to={`/dashboard/${selected_company._id}/view`}>{selected_company.name}</Link>}
                breadcrumb_2="Credit Events"
                icon="fas fa-users"
                actionComponent={(
                    <Link to={`/dashboard/${selected_company._id}/view`} className="btn btn-outline-warning">
                        <i className="fas fa-arrow-left " /> Back
                    </Link>
                )}
            />
    
            <Card className="card-color card-primary table-fixed">
                <Table
                    // placeholder="Name, Email, or Phone"
                    // downloadCSV={onDownloadCSV}
                    hideSearch={true}
                    query={query}
                    columns={columns}
                    data={tableData.data}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>

        </Container>
    )

}

const mapStateToProps = state => {
	return {
	    device: state.device,
        selected_company: state.company.selected_company,
	};
};

export default connect(mapStateToProps, '')(ContactsAll);
