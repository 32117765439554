/*
Documentation

*** make sure to declare all action types in actions.js one folder level back

*/

import * as actionTypes from '../../actions';

const initialState = {
    company: {},
    company_list: [],
    selected_company: {},
    selected_company_registration: ''
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SET_COMPANY:

            return {
                ...state,
                company: action.payload,
            }
        
        case actionTypes.SET_COMPANY_LIST:

            return {
                ...state,
                company_list: action.payload,
            }
        
        case actionTypes.SET_SELECTED_COMPANY:

            return {
                ...state,
                selected_company: action.payload,
            }
        case actionTypes.SET_SELECTED_COMPANY_REGISTRATION:
            return {
                ...state,
                selected_company_registration: action.payload,
            }

        default: return state;

    }

}

export default reducer;
