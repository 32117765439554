/*
Documentation

this page holds all the routes for the dashboard layout

*/


import AccountSettings          from '../pages/account/Settings';

import Dashboard                from '../pages/dashboard/index';

import AnalyticsCreditPurchases from '../pages/analytics/CreditPurchases';
import AnalyticsCreditEvents    from '../pages/analytics/CreditEvents';
import AnalyticsContacts        from '../pages/analytics/Contacts';
import AnalyticsDocuments       from '../pages/analytics/Documents';
import AnalyticsPortalSessions  from '../pages/analytics/PortalSessions';
import AnalyticsInternalCosts   from '../pages/analytics/InternalCosts';
import AnalyticsOverview        from '../pages/analytics/Overview';

import ChannelPartnersAll       from '../pages/channel_partners/All';
import ChannelPartnersCreate    from '../pages/channel_partners/Create';
import ChannelPartnersUpdate    from '../pages/channel_partners/Update';

import CompanyView              from '../pages/company/View';
import CompanyUpdate            from '../pages/company/Update';
import CompanyCreate            from '../pages/company/Create';

import ContactsAll              from '../pages/contacts/All';
import ContactsMaster           from '../pages/contacts/Master';
import ContactsView             from '../pages/contacts/View';

import CreditEventsView         from '../pages/credit_events/View';

import CreditPurchasesView      from '../pages/credit_purchases/View';
import CreditPurchasesAnalyze   from '../pages/credit_purchases/Analyze';

import DocumentsAll             from '../pages/documents/All';
import DocumentsMaster          from '../pages/documents/Master';

import FlaggedDocuments         from '../pages/flagged/Documents';

import Logs         from '../pages/logs';

import PackagesAll              from '../pages/packages/All';
import PackagesView             from '../pages/packages/View';

import PartnerLogsAll           from '../pages/partner_logs/All';
import PaymentLogsAll           from '../pages/payment_logs/All';
import IntegrationLogsAll       from '../pages/integration_logs/All';

import Triggers                 from '../pages/triggers';


const routes = () =>  [


  
    /***********************
    Analytics
    ************************/
    {
        path: "/analytics/credit_events",
        component: AnalyticsCreditEvents,
    },
    {
        path: "/analytics/credit_purchases",
        component: AnalyticsCreditPurchases,
    },
    {
        path: "/analytics/contacts",
        component: AnalyticsContacts,
    },
    {
        path: "/analytics/documents",
        component: AnalyticsDocuments,
    },
    {
        path: "/analytics/portal_sessions",
        component: AnalyticsPortalSessions,
    },
  
    {
        path: "/analytics/overview",
        component: AnalyticsOverview,
    },
  
    {
        path: "/:company_id/analytics/internal_costs",
        component: AnalyticsInternalCosts,
        requireCompany: true,
    },
  
    /***********************
    ACCOUNT
    ************************/
    {
        path: "/triggers",
        component: Triggers,
    },
  
    /***********************
    ACCOUNT
    ************************/
    {
        path: "/settings",
        component: AccountSettings,
    },

    /***********************
    Flagged
    ************************/
    {
        path: "/flagged/documents",
        component: FlaggedDocuments,
    },

    /***********************
    CHANNEL PARTNERS
    ************************/
    {
        path: "/channel_partners",
        component: ChannelPartnersAll,
    },
    {
        path: "/channel_partners/create",
        component: ChannelPartnersCreate,
    },
    {
        path: "/channel_partners/:channel_partner_id",
        component: ChannelPartnersUpdate,
    },
    /***********************
    COMPANY
    ************************/
    {
        path: "/:company_id/view",
        component: CompanyView,
        requireCompany: true,
    },
    {
        path: "/:company_id/update",
        component: CompanyUpdate,
        requireCompany: true,
    },
    {
        path: "/companies/new",
        component: CompanyCreate,
        requireCompany: true,
    },

    /***********************
    CONTACTS
    ************************/
    {
        path: "/contacts",
        component: ContactsMaster,
    },
    /***********************
    CONTACTS
    ************************/
    {
        path: "/:company_id/contacts",
        component: ContactsAll,
        requireCompany: true,
    },
    {
        path: "/:company_id/contacts/:contact_id",
        component: ContactsView,
        requireCompany: true,
    },
    /***********************
    CREDIT EVENTS
    ************************/
    {
        path: "/:company_id/credit_events",
        component: CreditEventsView,
        requireCompany: true,
    },
    /***********************
    CREDIT PURCHASES
    ************************/
    {
        path: "/:company_id/credit_purchases",
        component: CreditPurchasesView,
        requireCompany: true,
    },
    {
        path: "/:company_id/credit_purchases/analyse",
        component: CreditPurchasesAnalyze,
        requireCompany: true,
    },
    /***********************
    DOCUMENTS
    ************************/
    {
        path: "/:company_id/documents",
        component: DocumentsAll,
        requireCompany: true,
    },
    {
        path: "/documents",
        component: DocumentsMaster,
    },
   

    /***********************
    Billing
    ************************/
    {
        path: "/packages/all",
        component: PackagesAll,
    },
    {
        path: "/packages/:package_id",
        component: PackagesView,
    },

   
    /***********************
    Analytics
    ************************/
    {
        path: "/partner_logs",
        component: PartnerLogsAll,
    },
    {
        path: "/payment_logs",
        component: PaymentLogsAll,
    },
    {
        path: "/integration_logs",
        component: IntegrationLogsAll,
    },
    {
        path: "/dev-info",
        component: Logs,
    },
    /***********************
    DASHBOARD
    ************************/
    {
        path: "/home",
        component: Dashboard,
    },

    {
        path: "/",
        component: Dashboard,
    },

   
]

export default routes;
