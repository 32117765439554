
import apiWorker from '../apiWorker'

const partner_logs =  {

    searchContact : (contact_id, data) => apiWorker('post',`/v1/admin/partner_logs/search/contact/${contact_id}`, data),
    search : (data) => apiWorker('post',`/v1/admin/partner_logs/search`, data),

}

export default partner_logs;