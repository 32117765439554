import apiWorker from '../apiWorker'

const companies =  {
    creditPurchases   : (query) => apiWorker('get', `/v1/admin/analytics/credit_purchases${query}`, null),
    creditEvents      : (query) => apiWorker('get', `/v1/admin/analytics/credit_events${query}`, null),
    contacts          : (query) => apiWorker('get', `/v1/admin/analytics/contacts${query}`, null),
    documents         : (query) => apiWorker('get', `/v1/admin/analytics/documents${query}`, null),
    portalSessions    : (query) => apiWorker('get', `/v1/admin/analytics/portal_sessions${query}`, null),
    overview          : (query) => apiWorker('get', `/v1/admin/analytics/overview${query}`, null),
}

export default companies;