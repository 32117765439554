import LOGO from 'assets/img/brand/ZapReportsCut.png'
import LOGO_WHITE from 'assets/img/brand/ZapReportsLogo.png'

let keys = {
    LOGO,
    LOGO_WHITE,
    COMPANY: 'Zap Reports',
};

const HOSTNAME = window.location.hostname

//using production keys
if(HOSTNAME === 'admin.zapreports.com') {

    keys = {

        ...keys,

        SYSTEM_API_KEY            : 'architeck-58163098451',
        MX_CLIENT_ID              : '',
        API_URL                   : 'https://api.zapreports.com',
        REPORT_URL                : 'https://reports.zapreports.com',
        SITE_URL                  : 'https://zapreports.com',
        FORM_URL                  : 'https://forms.zapreports.com',
        SIGN_URL                  : 'https://sign.zapreports.com',
        CLIENT_URL                : 'https://client.zapreports.com',
        FAST_LINK_URL             : 'https://fl4.prod.yodlee.com/authenticate/USDevexProd3-263/fastlink?channelAppName=usdevexprod3',
        FAST_LINK_CONFIGURATION   : 'Config1',
    }

//using staging keys
} else if(HOSTNAME !== 'localhost') {

    keys = {

        ...keys,

        SYSTEM_API_KEY            : 'architeck-946154718',
        MX_CLIENT_ID              : '4f7d5f10-c1fb-4d43-b034-7d8d2296e1ba',
        API_URL                   : 'https://staging.api.zapreports.com',
        REPORT_URL                : 'https://staging.reports.zapreports.com',
        SITE_URL                  : 'https://staging.zapreports.com',
        FORM_URL                  : 'https://staging.forms.zapreports.com',
        SIGN_URL                  : 'https://staging.sign.zapreports.com',
        CLIENT_URL                : 'https://staging.client.zapreports.com',
        FAST_LINK_URL             : 'https://fl4.preprod.yodlee.com/authenticate/USDevexPreProd3-3/fastlink?channelAppName=usdevexpreprod3',
        FAST_LINK_CONFIGURATION   : 'Config1',
    }

//using development keys
} else {

    keys = {

        ...keys,

        SYSTEM_API_KEY            : 'architeck-',
        MX_CLIENT_ID              : '4f7d5f10-c1fb-4d43-b034-7d8d2296e1ba',
        API_URL                   : 'http://localhost:5004',
        REPORT_URL                : 'http://localhost:3001',
        SITE_URL                  : 'http://localhost:3003',
        FORM_URL                  : 'http://localhost:3004',
        CLIENT_URL                : 'http://localhost:3000',
        SIGN_URL                  : 'http://localhost:3006',
        FAST_LINK_URL             : 'https://fl4.preprod.yodlee.com/authenticate/USDevexPreProd3-3/fastlink?channelAppName=usdevexpreprod3',
        FAST_LINK_CONFIGURATION   : 'Config1',
    }

}

export default keys
