import { useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
import { Card, Container, Alert, CardBody, Badge } from "reactstrap";

import { Helmet } from 'react-helmet';


import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'
import Circle from 'components/markup/loading/Circle'
import moment from 'moment';

import { formatMoney, formatCredits } from 'utils/currency';
import _packages from '_functions/packages';

const PackagesAll = () => {

    const [packages, setPackages] = useState(null);
    const [err, setErr] = useState(false);

    const fetchPackages = useCallback(async () => {
        const foundPackages = await _packages.find();
        if(foundPackages.data) {
            setPackages(foundPackages.data)
        } else {
            setErr(true);
        }
    }, [])

    useEffect(() => {
        fetchPackages();
    }, [fetchPackages])

    if(err) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }

    if(!packages) return <Circle />

    return (
        <Container fluid>
    
            <Helmet>
                <title>{`All Packages`}</title>
                <meta name="description" content="Packages" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Billing</span>}
                breadcrumb_1="Packages"
                breadcrumb_2="All"
                icon="fas fa-dollar-sign"
                actionComponent={(
                    <Link to="/dashboard/packages/new" className="btn btn-success">
                        <i className="fas fa-plus mr-2 " /> Add
                    </Link>
                )}
            />
    
            <Card className="card-color card-primary table-fixed">

                {packages.length ? (
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Cost</th>
                                    <th>Credits</th>
                                    <th>Bonus Credits</th>
                                    <th>Can Reload</th>
                                    <th>Active</th>
                                    <th>Created</th>
                                    <th className="text-right">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {packages.map(p => (
                                    <tr>
                                        <td>
                                            <Link to={`/dashboard/packages/${p._id}`}>{p.name}</Link>
                                        </td>
                                        <td>{formatMoney(p.amount)}</td>
                                        <td>{formatCredits(p.credits)}</td>
                                        <td>{formatCredits(p.free_credits)}</td>
                                        <td>
                                            {p.can_reload ? (
                                                <Badge color="success"><i className="fas fa-check mr-2 " /> Yes</Badge>
                                            ) : (
                                                <Badge color="warning"><i className="fas fa-times mr-2 " /> No</Badge>
                                            )}
                                        </td>
                                        <td>
                                            {p.active ? (
                                                <Badge color="success"><i className="fas fa-check mr-2 " /> Yes</Badge>
                                            ) : (
                                                <Badge color="warning"><i className="fas fa-times mr-2 " /> No</Badge>
                                            )}
                                        </td>
                                        <td>{moment.unix(p.created_at).format('MM/DD/YYYY h:MM A')}</td>
                                        <td className="text-right">
                                            <Link 
                                                to={`/dashboard/packages/${p._id}`}
                                                className="btn btn-outline-success"
                                            >
                                                <i className="fas fa-edit mr-2 " />Update
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <CardBody>
                        <p className="text-sm mb-0">
                            No packages have been created yet.
                        </p>
                    </CardBody>
                )}

            </Card>

        </Container>
    )

}

export default PackagesAll
