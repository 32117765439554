import keys from 'keys';
import { useState, useCallback } from "react";
import Circle from 'components/markup/loading/Circle'

import { toggleAlertBS } from 'store/functions/system/system';

import _integrations from '_functions/integrations';


const MXConnect = ({contact, company, fetchYodleeAccounts}) => {

    const [connectLoaded, setConnectLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const closeFastLink = useCallback(() => {
        window.fastlink.close();
        setShowModal(false);
        setConnectLoaded(false)
    }, [])

    const openFastLink = useCallback(async () => {    

        setShowModal(true);

        const request = await _integrations.yodlee.tokens.create({
            contact_id: contact._id,
            company_id: company._id,
            access_code: contact.access_code,
        });

        if(!request.data) {
            closeFastLink()
            return toggleAlertBS(true, `We are experiencing technical difficulties, please try again in a few minutes. If this problem persists please contact support.`)
        }

        setTimeout(() => {
            setConnectLoaded(true)
        }, 2000)

        window.fastlink.open({
            fastLinkURL: keys.FAST_LINK_URL,
            accessToken: `Bearer ${request.data}`,
            params: {
                configName : keys.FAST_LINK_CONFIGURATION
            },
            onSuccess: function (data) {
                // will be called on success. For list of possible message, refer to onSuccess(data) Method.
                fetchYodleeAccounts();
            },
            onError: function (data) {
                // will be called on error. For list of possible message, refer to onError(data) Method.
                console.log(data);
            },
            onClose: function (data) {
                // will be called called to close FastLink. For list of possible message, refer to onClose(data) Method.
                closeFastLink()
            },
            onEvent: function (data) {
                // will be called on intermittent status update.
            }
        }, 'container-fastlink');

    }, [contact._id, contact.access_code, company._id, closeFastLink, fetchYodleeAccounts])

    return (

        <>

        <button  onClick={() => openFastLink()} className=" btn btn-outline-success btn-md-block mb-3 mb-md-0 mt-3 mt-md-0">
            Connect Banks
        </button>

        <div className={showModal ? "modal fade show" : "modal fade"} role="dialog" tabIndex="-1" style={{display: showModal ? 'block' : 'none'}}>
            <div className="modal-backdrop fade show" />
            <div id="test" className="modal-dialog modal-dialog-centered modal-lg" role="document" style={{maxWidth: 435, borderRadius: 10, margin: 0, marginLeft: 'auto', marginRight: 'auto', zIndex: 99999999}}>
                <div className="modal-content">
                    <div className="modal-body p-0" style={{minHeight: 400, borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
                        {!connectLoaded ? <div style={{position: 'relative', top: 325}}><Circle /></div> : null}
                        <div id="container-fastlink" />
                    </div>
                </div>
            </div>
        </div>

        </>

    )
}

export default MXConnect

