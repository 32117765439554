import apiWorker from '../apiWorker'

const integrations = {

    yodlee: {
        tokens: {
            create : (data) => apiWorker('post',  `/v1/integrations/yodlee/tokens/${data.contact_id}/${data.company_id}/${data.access_code}`, data, true),
        },
        accounts: {
            find   : (data) => apiWorker('get',     `/v1/integrations/yodlee/accounts/${data.contact_id}/${data.company_id}/${data.access_code}`, data, true),
            delete : (data) => apiWorker('delete',  `/v1/integrations/yodlee/accounts/${data.contact_id}/${data.company_id}/${data.access_code}`, data, true),
        },
    },
    
    mx: {
        request_url : (data) => apiWorker('post',  `/v1/integrations/mx/connect/request_url/${data.contact_id}/${data.company_id}/${data.access_code}`, null, true),
        members: {
            list : (data)   =>  apiWorker('get',  `/v1/integrations/mx/members/${data.contact_id}/${data.company_id}/${data.access_code}`, null, true),
            delete : (data) =>  apiWorker('delete',  `/v1/integrations/mx/members/${data.contact_id}/${data.company_id}/${data.access_code}/${data.member_id}`),
            extend : (data) =>  apiWorker('post',  `/v1/integrations/mx/extend/${data.contact_id}/${data.company_id}/${data.access_code}`, data),
            identity : (data) =>  apiWorker('post',  `/v1/integrations/mx/identity/${data.contact_id}/${data.company_id}/${data.access_code}`, data),
            statements : (data) =>  apiWorker('post',  `/v1/integrations/mx/statements/${data.contact_id}/${data.company_id}/${data.access_code}`, data),
        },

        accounts: {
            list : (data) =>  apiWorker('get',  `/v1/integrations/mx/accounts/${data.contact_id}/${data.company_id}/${data.access_code}`, null, true),
        },
        users: {
            create : (data) =>  apiWorker('post',  `/v1/integrations/mx/users`, data, true),
        }
    },

    pinwheel: {
        link_token : (data) =>  apiWorker('get',  `/v1/integrations/pinwheel/token/${data.contact_id}/${data.company_id}/${data.access_code}`, null, true),
    }

}

export default integrations;