import PropTypes from 'prop-types';
import React, { Component } from 'react';

import PreviewImage from './PreviewImage';
import PreviewPDF from './PreviewPDF';

import { toggleAlertBS } from 'store/functions/system/system';


const imageEndings = ['jpeg', 'jpg', 'png', 'tif', 'tiff', 'svg', 'gif', 'heic']

class Documents extends Component {

    state = {
        showPreviewImageModal: false,
        showPreviewPDFModal: false,
    };

    togglePreviewImageModal = () => this.setState({showPreviewImageModal: !this.state.showPreviewImageModal})
    togglePreviewPDFModal = () => this.setState({showPreviewPDFModal: !this.state.showPreviewPDFModal})

    onPreview = (doc, ending) => {

        if(imageEndings.includes(ending)) {
            this.togglePreviewImageModal()
        } else if(doc.url && doc.url.includes('.pdf')) {
            this.togglePreviewPDFModal()
        } else {
            toggleAlertBS('info', 'No preview is available for this document.')
        }
        
    }
 
    render() {

        const { showPreviewImageModal, showPreviewPDFModal } = this.state
        const { doc, children, approve, reject } = this.props

        const split = doc.name ? doc.name.split('.') : 'unknown.unknown'
        const ending = split[split.length - 1]

        return (

            <div className="archk-image-renderer">
                <span onClick={() => this.onPreview(doc, ending)}>
                    {children}
                </span>

                <PreviewImage 
                    showModal={showPreviewImageModal}
                    toggleModal={this.togglePreviewImageModal}
                    doc={doc}
                />

                <PreviewPDF 
                    showModal={showPreviewPDFModal}
                    toggleModal={this.togglePreviewPDFModal}
                    doc={doc}
                    approve={approve}
                    reject={reject}
                />

            </div>

        )

    }

}

Documents.propTypes = {
    doc: PropTypes.object.isRequired,
    maxNameLength: PropTypes.number,
}

export default Documents
