import moment from 'moment';
import { Badge } from "reactstrap";
import { getDownloadName } from 'utils/documents';

import { Link } from 'react-router-dom'

import ImageRender from 'components/functional/images/ImageRenderer'

import ExtendDownloadTime from "views/dashboard/pages/_components/Documents/ExtendDownloadTime";

import ModalToggler from 'components/functional/modals/Toggler'

import { needsExtension } from 'utils/documents'

const TableDocuments = ({documents}) => {
    
    const startOfDay = parseInt(moment().startOf('day').format('X'));

    return (
        <div className="table-responsive">
            {(
            <table className="table">
                <thead>
                    <tr>
                        <th>Document</th>
                        <th>Status</th>
                        <th>Attempts</th>
                        <th>Next Attempt</th>
                        <th>Info</th>
                        <th className="text-right">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {documents.length ? documents.map(document => (
                        <tr key={document._id}>
                            <td style={{minWidth: 150}}>
                                <Link to={`/dashboard/documents/${document._id}`}>
                                    {getDownloadName(document, document.contact)}
                                </Link>
                            </td>
                            <td>
                                {document.status === 'failed' ? (
                                    <Badge color="danger" className="tex-sm" styles={styles.badge}>{document.status}</Badge>
                                ) : document.status === 'completed' ? (
                                    <Badge color="success" className="tex-sm" styles={styles.badge}>{document.status}</Badge>
                                ) : (
                                    <Badge color="warning" className="tex-sm" styles={styles.badge}>{document.status}</Badge>
                                )}
                            </td>
                            <td>{document.status === 'completed' ? document.retries + 1 : document.retries}</td>
                            <td>
                                {document.status !== 'failed' && document.status !== 'completed' ? (
                                    document.next_retry_at > startOfDay ? (
                                        moment.unix(document.next_retry_at).format('h:mm A')
                                    ) : (
                                        moment.unix(document.next_retry_at).format('MM/DD/YYYY')
                                    )
                                ) : '-'}
                            </td>
                            <td>
                                <div style={{maxWidth: 200, minWidth: 100, whiteSpace: 'pre-line'}}>
                                    {document.messages[document.messages.length -1]}
                                </div>
                            </td>
                            <td className="text-right">
                                {document.status === 'completed' ? (
                                    needsExtension(document) ? (

                                        <ModalToggler component={ExtendDownloadTime} doc={document}>
                                            <button className="btn btn-info">Extend</button>
                                        </ModalToggler>     
                                    ) : (
                                        <ImageRender doc={document}>
                                            <button className="btn btn-outline-success">Preview</button>
                                        </ImageRender>
                                    )
                                                          
                                  
                             ) : '-'}
                            </td>
                        </tr>
                    )) : (
                        <tr>
                            <td>No Documents Created</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    )}
                </tbody>
            </table>
            )}
        </div>

    )

}

const styles = {
    badge: {
        display: 'inline-block',
        width: 120
    },
    badgeMobile: {
        display: 'inline-block',
        width: 30,
        marginRight: 10
    }
}

export default TableDocuments;