import apiWorker from '../apiWorker'

const auth = {

    mfa: {
        send: (data)  => apiWorker('get', `/v1/auth/mfa?_id=${data._id}&company=${data.company}&type=${data.type}`, null, true),   
        verify: (data)  => apiWorker('post', `/v1/auth/mfa`, data, true),   
    },

    password: {
        update: (data)  => apiWorker('post', `/v1/auth/password/update`, data, true),
        reset:  (data)  => apiWorker('post', `/v1/auth/password/reset`, data, true),
    },

    security_code: {
        create:   (data)  => apiWorker('post', `/v1/auth/security_code/create`, data, true),
        validate: (data)  => apiWorker('post', `/v1/auth/security_code/validate`, data, true),
    },

    logout:       ()      => apiWorker('post', `/v1/auth/logout`),
    login:        (data)  => apiWorker('post', `/v1/auth/login`, data, true),
    viewing_user: ()      => apiWorker('get', `/v1/auth/viewing_user`),

}

export default auth;