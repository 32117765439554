import { useState, useCallback, useEffect } from "react";

import _integrations from '_functions/integrations';
import _credit_events from '_functions/credit_events';

import { toggleAlertBS } from 'store/functions/system/system';

import PinwheelModal from '@pinwheel/react-modal'

const PinwheelLink = ({contact, company, accounts, onSetAccounts}) => {

    const [token, setToken] = useState(false);
    const [platform, setPlatform] = useState(false);
    const [account, setAccount] = useState(false);

    const openLink = useCallback(async () => {

        const token = await _integrations.pinwheel.link_token({
            contact_id: contact._id,
            company_id: company._id,
            access_code: contact.access_code,
        })

        if(token.data && token.data.token) return setToken(token.data.token)

        if(token.message && token.message[0] && token.message[0] === 'staging') {
            return  toggleAlertBS(true, `Document Generation Is Not Allowed In The Staging Environment.`)
        } else if(token.message && token.message[0] && token.message[0] === 'The terms of use must be accepted before pulling documents.') {
            toggleAlertBS(true, `The terms of use must be accepted before pulling documents.`)
        } else {
            return toggleAlertBS(true, `We are experiencing technical difficulties, please try again in a few minutes. If this problem persists please contact support.`)
        }
        

    }, [contact._id, contact.access_code, company._id])

    const onEvent = useCallback((event, payload) => {

        switch (event) {
            case 'exit':
                setToken(false)
                break;
        
            case 'select_platform':
                setPlatform(payload.selectedPlatformName)
                break;
            case 'success':
                setAccount({account_id: payload.accountId, platform_id: payload.platformId})
                break;
        
            default:
                break;
        }
    }, [])

    const checkCredits = useCallback(async () => {
        const check = await _credit_events.check(contact._id, 'pinwheel');
        if(!check.data || (check.data && !check.data.allowed)) {
            setToken(false);
            toggleAlertBS(true, `Your account does not have enough credits to link employer documents.`)
            const modal = document.getElementById('pinwheel-portal')
            modal.parentNode.removeChild(modal);

        }
    }, [contact._id, setToken])

    useEffect(() => {
        if(token) {
            checkCredits()
        }
    }, [checkCredits, token])

    useEffect(() => {

        if(account.account_id && account.platform_id && platform) {
            const a = [...accounts];
            a.push({account_id: account.account_id, platform_id: account.platform_id, platform});
            onSetAccounts(a)
            setPlatform(false)
            setAccount(false)
        }

    }, [onSetAccounts, account.account_id, account.platform_id, accounts, platform, token, contact._id, contact.access_code, company._id]);

    return (

        <button onClick={openLink} className=" btn btn-outline-warning  btn-md-block mb-3 mb-md-0 mt-3 mt-md-0">
            {token ? (
                <PinwheelModal
                    open={true}
                    linkToken={token}
                    onEvent={onEvent}
                />
            ) : null}
            Link Employment
        </button>

    )
}

export default PinwheelLink