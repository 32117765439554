import { Nav, NavItem, NavLink, Card, CardBody } from "reactstrap";

const tabs = [ 'Overview', 'Logs', 'Timeline', 'Connect', 'Credits', 'Properties' ];


const ContactsNavigation = ({tab, onSetTab}) => {

    const getClasses = (tabToCheck) => {

        if(tabToCheck === tab) {
            return 'mb-sm-3 mb-md-0 active'
        }

        return 'mb-sm-3 mb-md-0'

    }

    return (

        <Card>
            <CardBody>
                <Nav className="nav-fill flex-column flex-sm-row" pills role="tablist" >
                    {tabs.map((t, i) => (
                        <NavItem key={i}>
                            <NavLink
                                aria-selected={tab === i}
                                className={getClasses(i)}
                                onClick={e => onSetTab(i)}
                                role="tab"
                            >
                                {t}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
            </CardBody>
        </Card>
        
    );

}

export default ContactsNavigation;