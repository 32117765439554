import apiWorker from '../apiWorker'

const documents = {
    search                : (company_id, data) =>  apiWorker('post',  `/v1/admin/${company_id}/documents`, data),
    createList            : (company_id, data) =>  apiWorker('post',  `/v1/admin/${company_id}/documents/create_list`, data),
    byContact             : (company_id, contact_id) =>  apiWorker('get',  `/v1/admin/${company_id}/documents/contact/${contact_id}`),
    extendDownloadTime    : (company_id, document_id, data) =>  apiWorker('patch',  `/v1/admin/${company_id}/documents/${document_id}/extend`, data),
}

export default documents;

// import apiWorker from '../apiWorker'

// const documents = {
//     search              : (data)        =>  apiWorker('post',  `/v1/api/documents/search`, data),
//     getReport           : (report_id)   =>  apiWorker('get',  `/v1/api/documents/get_report/${report_id}`, null, true),
//     byContact           : (contact_id)  =>  apiWorker('get',  `/v1/api/documents/contact/${contact_id}`, null, true),
//     credit              : (data)        => apiWorker('post', `/v1/api/documents/credit/mx/${data.contact}/${data.company}/${data.access_code}`, data),
//     connectedMX         : (data)        => apiWorker('post', `/v1/api/documents/connected/mx/${data.contact}/${data.company}/${data.access_code}`, data),
//     connectedPinwheel   : (data)        => apiWorker('post', `/v1/api/documents/connected/pinwheel/${data.contact}/${data.company}/${data.access_code}`, data),
//     findById            : (document_id) => apiWorker('get', `/v1/api/documents/${document_id}`, null, true),
//     fetchAnalysis       : (contact_id)  => apiWorker('get', `/v1/api/documents/contact/${contact_id}/analyze/`, null, true),
//     createList          : (data)        => apiWorker('post', `/v1/api/documents/createList/`, data, true),
// }

// export default documents;