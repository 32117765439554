import { connect } from 'react-redux';
import { useEffect, useState, useCallback } from "react";
import { Helmet } from 'react-helmet';

import { Card, Container, Alert, Row, Col } from "reactstrap";

import Circle from 'components/markup/loading/Circle'
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'

import { formatCredits } from 'utils/currency';

import _analytics from '_functions/analytics'

import AnalyticsSidebar from '../_components/Sidebar'

const AnalyticsContacts = ({analytics_query_date, analytics_company}) => {

    const [ data, setData ] = useState(null)
    const [ err, setErr ] = useState(false)

    const fetchData = useCallback(async (_date, company) => {
        setData(null)
        const data = await _analytics.contacts(`?timeframe=${_date}&company=${company}`);
        if(data.data) {
            setData(data.data)
        } else {
            setErr('true')
        }
    }, [])

    const onQuery = useCallback(() => {
        fetchData(analytics_query_date, analytics_company)
    }, [fetchData, analytics_query_date, analytics_company])

    useEffect(() => {
        if(!data) fetchData(analytics_query_date, analytics_company)
    }, [fetchData, analytics_query_date, analytics_company, data])

    if(err) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }

    return (
        <Container fluid>
    
            <Helmet>
                <title>{`Analytics`}</title>
                <meta name="description" content="Analytics" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Analytics</span>}
                breadcrumb_1={'Contacts'}
                icon="fas fa-chart-pie"
            />
    
            <Card className="card-color card-primary table-fixed">

                <Row className="archk-internal-sidebar">

                    <AnalyticsSidebar 
                        onQuery={onQuery}
                        data={data}
                        show={{
                            company: true
                        }}
                    />

                    <Col className="border-left">
                      
                        {!data ? (
                            <div className="py-6"><Circle /></div>
                        ) : (
                            <>
                            <div className="table-responsive border-bottom">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th className="text-right">Number</th>
                                            <th className="text-right">Percent</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.results && data.results.contacts ? (
                                            <>
                                            <tr>
                                                <td>No Status</td>
                                                <td className="text-right">{data.results.contacts.null}</td>
                                                <td className="text-right">{((data.results.contacts.null / data.results.contacts.total) * 100).toFixed(2)}%</td>
                                            </tr>
                                            <tr>
                                                <td>Requested</td>
                                                <td className="text-right">{data.results.contacts.requested}</td>
                                                <td className="text-right">{((data.results.contacts.requested / data.results.contacts.total) * 100).toFixed(2)}%</td>
                                            </tr>
                                            <tr>
                                                <td>In Progress</td>
                                                <td className="text-right">{data.results.contacts.in_progress}</td>
                                                <td className="text-right">{((data.results.contacts.in_progress / data.results.contacts.total) * 100).toFixed(2)}%</td>
                                            </tr>
                                            <tr>
                                                <td>Complete</td>
                                                <td className="text-right">{data.results.contacts.complete}</td>
                                                <td className="text-right">{((data.results.contacts.complete / data.results.contacts.total) * 100).toFixed(2)}%</td>
                                            </tr>
                                            <tr>
                                                <td>Joint</td>
                                                <td className="text-right">{data.results.contacts.partner}</td>
                                                <td className="text-right">{((data.results.contacts.partner / data.results.contacts.total) * 100).toFixed(2)}%</td>
                                            </tr>
                                            <tr>
                                                <td>Total</td>
                                                <td className="text-right">{data.results.contacts.total}</td>
                                                <td className="text-right">100%</td>
                                            </tr>
                                            </>
                                        ) : (
                                            <tr>
                                                <td>No Data To Show</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            <div className="table-responsive border-bottom">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Credits Spent</th>
                                            <th className="text-right">Average Per Contact</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.results && data.results.contacts ? (
                                            <>
                                            <tr>
                                                <td>{formatCredits(data.results.totalCredits)}</td>
                                                <td className="text-right">{(formatCredits(data.results.totalCredits) / data.results.contacts.total).toFixed(2)}</td>
                                            </tr>
                                            </>
                                        ) : (
                                            <tr>
                                                <td>No Data To Show</td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            </>
                        )}
                    </Col>

                </Row>
               
            </Card>
        </Container>
    )

}

const mapStateToProps = state => {
	return {
        analytics_company: state.system.analytics_company,
	    analytics_query_date: state.system.analytics_query_date,
	};
};

export default connect(mapStateToProps, '')(AnalyticsContacts);