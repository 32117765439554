import { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle, Container } from 'reactstrap';
import { Helmet } from 'react-helmet';

import moment from 'moment';


import _credit_purchases from '_functions/credit_purchases'
import { formatMoney, formatCredits } from 'utils/currency';

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'
import Circle from 'components/markup/loading/Circle'

const resultNum = 15;

const CreditPurchases = ({selected_company}) => {

    const [purchases, setPurchases] = useState(null);

    const [err, setErr] = useState(null);
    const [total, setTotal] = useState(0);
    const [done, setDone] = useState(false);

    const setDataToState = useCallback((prevPurchases, data) => {
        if(data) {

            const { documents, total_documents } = data;

            let newPurchases = prevPurchases ? JSON.parse(JSON.stringify(prevPurchases)).concat(documents) : documents;

            setPurchases(newPurchases);
            setTotal(newPurchases.length)

            if(newPurchases.length === total_documents) {
                setDone(true)
            }

        } else {
            setErr(true)
        }
    }, [])

    const fetchMorePurchases = useCallback(async () => {
        const foundPurchases = await _credit_purchases.search(selected_company._id, {limit: resultNum, skip: total});
        setDataToState(purchases, foundPurchases.data)
    }, [setDataToState, purchases, total, selected_company._id])

    const getRecentPurchases = useCallback(async () => {
        const foundPurchases = await _credit_purchases.search(selected_company._id, {limit: resultNum});
        setDataToState(null, foundPurchases.data)
    }, [setDataToState, selected_company._id])

    useEffect(() => {
        getRecentPurchases()
    }, [getRecentPurchases])

    if(err) return <div className="alert alert-danger">Something's not right, please try again later.</div>

    return (

        <Container fluid>
    
            <Helmet>
                <title>{`Credit Purchases`}</title>
                <meta name="description" content="Credit Purchases" />
            </Helmet>

             <DashHeaderOpen 
                title={<span>Companies</span>}
                breadcrumb_1={<Link to={`/dashboard/${selected_company._id}/view`}>{selected_company.name}</Link>}
                breadcrumb_2="Credit Purchases"
                icon="fas fa-users"
                actionComponent={(
                    <Link to={`/dashboard/${selected_company._id}/view`} className="btn btn-outline-warning">
                        <i className="fas fa-arrow-left " /> Back
                    </Link>
                )}
            />
            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Purchase History</CardTitle>
                </CardHeader>

                { purchases && purchases.length ? (
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Amount</th>
                                    <th>Tax</th>
                                    <th>Total</th>
                                    <th>Credits</th>
                                    <th>Balance</th>
                                    <th>Cost Per Credit</th>
                                    <th className="text-right">User</th>
                                </tr>
                            </thead>
                            <tbody>
                                {purchases.map(p => (
                                    <tr key={p._id}>
                                        <td>{moment(p.date).format('MM/DD/YYYY h:mm A    ')}</td>
                                        <td style={{whiteSpace: 'pre'}}>{formatMoney(p.usd_amount)}</td>
                                        <td style={{whiteSpace: 'pre'}}>{formatMoney(p.usd_tax)}</td>
                                        <td style={{whiteSpace: 'pre'}}>{formatMoney(p.usd_total)}</td>
                                        <td style={{whiteSpace: 'pre'}}>{formatCredits(p.credits)}</td>
                                        <td style={{whiteSpace: 'pre'}}>{formatCredits(p.balance)}</td>
                                        <td style={{whiteSpace: 'pre'}}>{formatMoney(p.usd_amount_per_credit)}</td>
                                        <td className="text-capitalize text-right">
                                            {p.user ? p.user.given_name + ' ' + p.user.family_name : 'Auto Loader'}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : purchases ? (
                    <CardHeader>
                        <p className="text-sm mb-0"><i className="fas fa-info-circle text-info mr-2 " /> Your account has not purchased any credits</p>
                    </CardHeader>
                ) : (
                    <Circle />
                )}
                
            </Card>

            {purchases ? (
                done ? (
                    <p className="text-sm mt-3 text-center">All purchases have been loaded for your account.</p>
                ) : (
                    <div className="text-center mt-3">
                        <button className="btn btn-success" onClick={fetchMorePurchases}>
                            Load More
                        </button>
                    </div>
                )
            ) : null}
        </Container>
    )

}


const mapStateToProps = state => {
	return {
        selected_company: state.company.selected_company,
	};
};

export default connect(mapStateToProps, '')(CreditPurchases);