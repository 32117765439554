import { connect } from 'react-redux';import { useEffect, useState, useCallback } from "react";
import { Helmet } from 'react-helmet';
import { secondsToTime } from 'utils/time'

import { Card, Container, Alert, Row, Col } from "reactstrap";

import Circle from 'components/markup/loading/Circle'
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'

import _analytics from '_functions/analytics'

import AnalyticsSidebar from '../_components/Sidebar'

const AnalyticsDocuments = ({analytics_query_date, analytics_company}) => {

    const [ data, setData ] = useState(null)
    const [ err, setErr ] = useState(false)

    const fetchData = useCallback(async (_date, company) => {
        setData(null)
        const data = await _analytics.portalSessions(`?timeframe=${_date}&company=${company}`);
        if(data.data) {
            setData(data.data)
        } else {
            setErr('true')
        }
    }, [])

    const getPercent = useCallback((value, total) => {
        const number = ((value / total) * 100).toFixed(2);
        if(Number.isNaN(number)) return '-'
        return number + '%'
    }, [])
    const getDayOfWeek = useCallback((number) => {
        switch (number) {
            case 1: return 'Monday'
            case 2: return 'Tuesday'
            case 3: return 'Wednesday'
            case 4: return 'Thursday'
            case 5: return 'Friday'
            case 6: return 'Saturday'
            case 0: return 'Sunday'
            default: return '-';
        }
    }, [])
    const getHourOfDay = useCallback((number) => {
        switch (number) {
            case 0: return '12AM'
            case 1: return '1AM'
            case 2: return '2AM'
            case 3: return '3AM'
            case 4: return '4AM'
            case 5: return '5AM'
            case 6: return '6AM'
            case 7: return '7AM'
            case 8: return '8AM'
            case 9: return '9AM'
            case 10: return '10AM'
            case 11: return '11AM'
            case 12: return '12PM'
            case 13: return '1PM'
            case 14: return '2PM'
            case 15: return '3PM'
            case 16: return '4PM'
            case 17: return '5PM'
            case 18: return '6PM'
            case 19: return '7PM'
            case 20: return '8PM'
            case 21: return '9PM'
            case 22: return '10PM'
            case 23: return '11PM'
            default: return '-';
        }
    }, [])

    const onQuery = useCallback(() => {
        fetchData(analytics_query_date, analytics_company)
    }, [fetchData, analytics_query_date, analytics_company])

    useEffect(() => {
        if(!data) fetchData(analytics_query_date, analytics_company)
    }, [fetchData, analytics_query_date, analytics_company, data])

    if(err) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }

    return (
        <Container fluid>
    
            <Helmet>
                <title>{`Analytics`}</title>
                <meta name="description" content="Analytics" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Analytics</span>}
                breadcrumb_1={'Portal Sessions'}
                icon="fas fa-chart-pie"
            />
    
            <Card className="card-color card-primary table-fixed">

                <Row className="archk-internal-sidebar">

                    <AnalyticsSidebar 
                        onQuery={onQuery}
                        data={data}
                        show={{
                            company: true
                        }}
                    />

                    <Col className="border-left">
                      
                        {!data ? (
                            <div className="py-6"><Circle /></div>
                        ) : (
                            <>
                            <div className="table-responsive border-bottom">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Average Timelines</th>
                                            <th className="text-right">Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Time On Portal</td>
                                            <td className="text-right">{data.averageTime ? secondsToTime(data.averageTime) : '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>Time To Login</td>
                                            <td className="text-right">{data.contactTimeline.time_to_login ? secondsToTime(data.contactTimeline.time_to_login) : '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>Time To Accept Terms</td>
                                            <td className="text-right">{data.contactTimeline.time_to_accept_terms ? secondsToTime(data.contactTimeline.time_to_accept_terms) : '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>Time To Finish Portal</td>
                                            <td className="text-right">{data.contactTimeline.time_to_finish ? secondsToTime(data.contactTimeline.time_to_finish) : '-'}</td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-responsive border-bottom">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Page Ended On</th>
                                            <th className="text-right">Number</th>
                                            <th className="text-right">Percent</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.lastComponentAccessed && data.lastComponentAccessed.length ? data.lastComponentAccessed.map((d, i) => (
                                            <tr key={i}>
                                                <td>{d.field}</td>
                                                <td className="text-right">{d.total}</td>
                                                <td className="text-right">{getPercent(d.total, data.totals.lastComponentAccessed)}</td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td>No data to show</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-responsive border-bottom">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Day Of Week</th>
                                            <th className="text-right">Number</th>
                                            <th className="text-right">Percent</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.dayOfWeek && data.dayOfWeek.length ? data.dayOfWeek.map((d, i) => (
                                            <tr key={i}>
                                                <td>{getDayOfWeek(d.field)}</td>
                                                <td className="text-right">{d.total}</td>
                                                <td className="text-right">{getPercent(d.total, data.totals.dayOfWeek)}</td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td>No data to show</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-responsive border-bottom">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Time (Localized)</th>
                                            <th className="text-right">Number</th>
                                            <th className="text-right">Percent</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.hourOfDay && data.hourOfDay.length ? data.hourOfDay.map((d, i) => (
                                            <tr key={i}>
                                                <td>{getHourOfDay(d.field)}</td>
                                                <td className="text-right">{d.total}</td>
                                                <td className="text-right">{getPercent(d.total, data.totals.hourOfDay)}</td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td>No data to show</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            
                            <div className="table-responsive border-bottom">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Events Of Total Sessions</th>
                                            <th className="text-right">Number</th>
                                            <th className="text-right">Percent</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Failed Name Match</td>
                                            <td className="text-right">{data.checkPoints.failed_to_match_name}</td>
                                            <td className="text-right">{getPercent(data.checkPoints.failed_to_match_name, data.checkPoints.total)}</td>
                                        </tr>
                                        <tr>
                                            <td>Failed Logins</td>
                                            <td className="text-right">{data.checkPoints.failed_log_ins}</td>
                                            <td className="text-right">{getPercent(data.checkPoints.failed_log_ins, data.checkPoints.total)}</td>
                                        </tr>
                                        <tr>
                                            <td>Successful Logins</td>
                                            <td className="text-right">{data.checkPoints.successful_log_ins}</td>
                                            <td className="text-right">{getPercent(data.checkPoints.successful_log_ins, data.checkPoints.total)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-responsive border-bottom">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Devices</th>
                                            <th className="text-right">Number</th>
                                            <th className="text-right">Percent</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.deviceType && data.deviceType.length ? data.deviceType.map((d, i) => (
                                            <tr key={i}>
                                                <td>{d.field}</td>
                                                <td className="text-right">{d.total}</td>
                                                <td className="text-right">{getPercent(d.total, data.totals.deviceType)}</td>
                                            </tr>
                                        ))  : (
                                            <tr>
                                                <td>No data to show</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-responsive border-bottom">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Operation System</th>
                                            <th className="text-right">Number</th>
                                            <th className="text-right">Percent</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.os && data.os.length ? data.os.map((d, i) => (
                                            <tr key={i}>
                                                <td>{d.field}</td>
                                                <td className="text-right">{d.total}</td>
                                                <td className="text-right">{getPercent(d.total, data.totals.os)}</td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td>No data to show</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-responsive border-bottom">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Timezone</th>
                                            <th className="text-right">Number</th>
                                            <th className="text-right">Percent</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.timezone && data.timezone.length ? data.timezone.map((d, i) => (
                                            <tr key={i}>
                                                <td>{d.field}</td>
                                                <td className="text-right">{d.total}</td>
                                                <td className="text-right">{getPercent(d.total, data.totals.timezone)}</td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td>No data to show</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-responsive border-bottom">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Browsers</th>
                                            <th className="text-right">Number</th>
                                            <th className="text-right">Percent</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.browser.map((d, i) => (
                                            <tr key={i}>
                                                <td>{d.field}</td>
                                                <td className="text-right">{d.total}</td>
                                                <td className="text-right">{getPercent(d.total, data.totals.browser)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {data.failedURLs && data.failedURLs.length ? (
                                <div className="mt-3 px-4">
                                    <p className="mb-0 font-weight-bold">Failed URLS</p>
                                    {data.failedURLs.map((url, i) => (
                                        <p key={i} className="text-sm mb-0">{url}</p>
                                    ))}
                                </div>
                            ) : null}
                            
                            </>
                        )}

                    </Col>

                </Row>
               
            </Card>
        </Container>
    )

}


const mapStateToProps = state => {
	return {
	    analytics_company: state.system.analytics_company,
	    analytics_query_date: state.system.analytics_query_date,
	};
};

export default connect(mapStateToProps, '')(AnalyticsDocuments);