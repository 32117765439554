import keys from 'keys'
import { useState } from "react";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Card, Container, Alert } from "reactstrap";

import _channel_partners from '_functions/channel_partners';

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'
import Table, { downloadCSV } from 'components/functional/tables/Standard';
import A from 'components/markup/links/A'

const ChannelPartnersAll = () => {

    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ error, setError ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        const query = await _channel_partners.search({ ...params, isCSV });
        
        if(query.data) {
            return resolve(query.data)
        } else {
            setError(true)
        }
    })

    const onDownloadCSV = async (params) => {
        const data = await fetchData(params, true);
        downloadCSV(data, { name: 'contacts' });
    }

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {    
            dataField: "name",
            text: "Name",
            formatter: (cell, row) => (
                <Link className="text-capitalize" to={`/dashboard/channel_partners/${row._id}`}>
                    {row.name}
                </Link>
            ),
        },
        {    
            dataField: "custom_link",
            text: "Custom Link",
            formatter: (cell, row) => {
                const link = `${keys.CLIENT_URL}/auth/register/?p=${row.custom_link}`
                return (
                    <A href={link}>{link}</A>
                )
            }
        },
       

        {
            dataField: "_id",
            text: "Actions",
            headerStyle: {textAlign: 'right', width: 100},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <Link className="text-capitalize" to={`/dashboard/channel_partners/${row._id}`}>
                            <i className="fas fa-edit text-success mr-2"></i>
                        </Link>
                    </div>
                )
            }
        }
    ]

    if(error) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }

    return (
        <Container fluid>
    
            <Helmet>
                <title>{`Channel Partners`}</title>
                <meta name="description" content="Channel Partners" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Channel Partners</span>}
                breadcrumb_1={'All'}
                icon="fas fa-handshake"
                actionComponent={(
                    <Link to={`/dashboard/channel_partners/create`} className="btn btn-outline-success">
                        <i className="fas fa-plus " /> Add New
                    </Link>
                )}
            />
    
            <Card className="card-color card-primary table-fixed">
                <Table
                    placeholder="Name, Email, or Phone"
                    query={query}
                    columns={columns}
                    downloadCSV={onDownloadCSV}
                    data={tableData.data}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>


        </Container>
    )

}

export default ChannelPartnersAll;