import { useCallback, useEffect, useState } from 'react';
import { Modal, UncontrolledCollapse } from "reactstrap";

const MXConnect = ({showModal, toggleModal, selectedMX, selectedPinwheel, selectedYodlee, onConfirm}) => {

    const [checked, setChecked] = useState(false);

    const onSetChecked = useCallback(() => {
        setChecked(!checked)
    }, [checked]);

    useEffect(() => {
        if(showModal) setChecked(false);
    }, [showModal])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >
    
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Create Report</h5>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => toggleModal(false)} >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
    
            {selectedMX.length || selectedPinwheel.length || selectedYodlee.length ? (
                <div>
                    
                    <div className="modal-body bg-secondary">
                        <p className="text-sm mb-0">Your Report Will Be Created With The Following Documents:</p>
                    </div>
    
                    <div className="modal-body">
                        <ol className="my-3">
                            {selectedPinwheel.length ? selectedPinwheel.map((account, i) => (
                                <li className="bg-white" key={i}>
                                    {account.platform}
                                </li>
                            )) : null}
                            {selectedYodlee.length ? selectedYodlee.map((account, i) => (
                                <li className="bg-white" key={i}>
                                    {account.accountName}
                                </li>
                            )) : null}
                            {selectedMX.length ? selectedMX.map((member, i) => (
                                <li className="bg-white" key={i}>
                                    {member.name}
                                </li>
                            )) : null}
                        </ol>
                    </div>

                    <div className="modal-body bg-secondary mb-0">

                        <div className="custom-control custom-checkbox d-inline-block">
                            <input
                                className="custom-control-input"
                                id={`fetch-bank-statements`}
                                type="checkbox"
                                checked={checked}
                                onChange={onSetChecked}
                            />
                                <label className="custom-control-label cursor-pointer" htmlFor={`fetch-bank-statements`}>Search For Bank Statements</label>
                        </div>

                        <i 
                            id="info-bank-statements" 
                            className="fas fa-info-circle text-info ml-3 cursor-pointer p-3 " 
                            style={{position: 'relative', top: 1}} 
                        />

                    </div>

                    <UncontrolledCollapse toggler="#info-bank-statements">
                        <div className="modal-body">
                            <p className="text-sm">Opting to search for statements will instruct Zap Reports to return Bank Statements (when available) along with the standard transaction history for each account in the above list. </p>
                            <p className="text-sm mb-0"><b className="text-dark text-underline">NOTE</b> that this action can prompt a contact's accounts to require verification once again.</p>
                        </div>
                    </UncontrolledCollapse>

                    <div className="card-footer text-right">
                        <button onClick={() => onConfirm(checked)} className="btn btn-outline-success"><i className="fas fa-file mr-2 " /> Create</button>
                    </div>
    
                </div>
            ) : (
                <div>
                    <div className="modal-body">
                        <p className="text-sm mb-0">
                            <i className="fas fa-exclamation-triangle text-danger mr-2 " />
                            No Accounts Have Been Selected To Create A Report With
                        </p>
                    </div>
                    <div className="card-footer text-right">
                        <button onClick={toggleModal} className="btn btn-outline-warning">Close</button>
                    </div>
    
                </div>
            )}
    
        </Modal>
    )
}

export default MXConnect