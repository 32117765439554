import React from "react";
import { Link } from 'react-router-dom';
import { Col, Container, Row, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { connect } from 'react-redux';
import keys from 'keys';
import A from 'components/markup/links/A'

import { getCompanyLink } from 'utils/helpers';

const NavbarDesktop = ({company, selected_company}) => {

    const URI = getCompanyLink(selected_company._id ? selected_company : '')
    const portalURL = selected_company._id ? `${keys.REPORT_URL}/${URI}` : null

    return (

        <Container id="desktop-navbar-top" fluid>


            <div className="header">

                <div className="header-body">
                    <Row className="align-items-center pt-4 pb-3">

                        <Col lg="9" >
                            <ul>
                                <li>

                                    {selected_company.name ? (
                                        <b className="mr-3">{selected_company.name}</b>
                                    ) : ''}

                                    {portalURL ? (
                                        <A href={portalURL} className="navbar-portal-link" ><i className="fas fa-link mr-2 text-success " /> {portalURL}</A>
                                    ) : (
                                        ''
                                    )}

                                </li>
                            </ul>
                           
                        </Col>

                        <Col lg="3"  className="text-right">

                            <Nav className="align-items-center ml-auto ml-md-0 pl-8"  navbar>
                                <UncontrolledDropdown nav>
                                    <DropdownToggle className="nav-link pr-0 cursor-pointer font-weight-bold" color="" tag="a">
                                        Admin <i className="fas fa-caret-down ml-2 " />
                                    </DropdownToggle>

                                    <DropdownMenu right>
                                        <div className="dropdown-content">
                                            <DropdownItem className="noti-title" header tag="div">
                                                <h6 className="text-overflow m-0">Settings</h6>
                                            </DropdownItem>
                                            
                                            <DropdownItem tag={Link} to="/dashboard/settings" >
                                                <i className="ni ni-single-02" />
                                                <span>My Account</span>
                                            </DropdownItem>

                                            <DropdownItem tag={Link} to="/auth/logout" >
                                                <i className="ni ni-user-run" />
                                                <span>Logout</span>
                                            </DropdownItem>
                                        </div>
                                    </DropdownMenu>

                                </UncontrolledDropdown>
                            </Nav>

                        </Col>


                    </Row>
                </div>

            </div>
        </Container>

    )

}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	    company: state.company.company,
	    selected_company: state.company.selected_company,
	};
};

export default connect(mapStateToProps, '')(NavbarDesktop);
