/*
Documentation

This page handles updating a users profile

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';

import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Container, Card, CardTitle, CardHeader, CardBody } from "reactstrap";

import _total_connections from '_functions/total_connections'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

const AccountSettings = ({company}) => {

    const compileConnections = async () => {
        toggleStandardLoader(true)
        const compiled = await _total_connections.compile();
        if(compiled.success) {
            toggleAlertBS(false, 'Compiled Successfully')
        } else {
            toggleAlertBS(true, 'Something is not right.')
        }
        toggleStandardLoader(false)
    }

    return (
        <Container fluid>

            <Helmet>
                <title>Triggers</title>
                <meta name="description" content="Triggers" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Triggers</span>}
                breadcrumb_1="All"
                icon="fas fa-code"
            />

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0 text-danger  ">
                        NO ONE TOUCHES THIS PAGE BUT JOHN. NO ONE!
                    </CardTitle>
                    <p className="text-sm mb-0">If you're thinking about touching this page don't. Touch Something Else Instead.</p>
                </CardHeader>

                <CardBody>
                    <button onClick={compileConnections} className="btn btn-warning">Compile Connections</button>
                </CardBody>
            </Card>

        </Container>
    )

}

const mapStateToProps = state => {
    return {
        company: state.company.company,
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(AccountSettings);