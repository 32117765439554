
import React from "react";
import { connect } from 'react-redux';
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";

import keys from 'keys';

import { Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav, Badge } from "reactstrap";

class Sidebar extends React.Component {
  
    state = {
        collapseOpen: false,
        routes: [

            // {
            //     path: "/dashboard/reports",
            //     name: "Reports",
            //     icon: "fas fa-file-upload text-green",
            //     counter: 57
            // },
        
        
            { divider: 'My Dashboard' },
        
            {
                path: "/dashboard/home",
                name: "Home",
                icon: "fas fa-home text-green",
            },
            {
                path: "/dashboard/documents",
                name: "Documents",
                icon: "fas fa-file text-green",
            },
            {
                path: "/dashboard/contacts",
                name: "Contacts",
                icon: "fas fa-user text-green",
            },

            { divider: 'Monitoring' },
        
            {
                path: "/dashboard/flagged/documents",
                name: "Flagged Docs",
                icon: "fas fa-file text-purple",
            },
        
        
            { divider: 'Analytics' },
        
            {
                path: "/dashboard/analytics/overview",
                name: "Overview",
                icon: "fas fa-chart-pie text-info",
            },
            {
                path: "/dashboard/analytics/credit_events",
                name: "Credit Events",
                icon: "fas fa-coins text-info",
            },
        
            {
                path: "/dashboard/analytics/credit_purchases",
                name: "Credit Purchases",
                icon: "fas fa-dollar-sign text-info",
            },
            {
                path: "/dashboard/analytics/contacts",
                name: "Contacts",
                icon: "fas fa-users text-info",
            },
            {
                path: "/dashboard/analytics/documents",
                name: "Documents",
                icon: "fas fa-file text-info",
            },
            {
                path: "/dashboard/analytics/portal_sessions",
                name: "Portal Sessions",
                icon: "fas fa-user-secret text-info",
            },

            { divider: 'Partners' },
        
            {
                path: "/dashboard/channel_partners",
                name: "Channel Partners",
                icon: "fas fa-file text-success",
            },
                
            { divider: 'Billing' },
        
            {
                path: "/dashboard/packages/all",
                name: "Packages",
                icon: "fas fa-dollar-sign text-warning",
            },

            { divider: 'Developers' },

            {
                collapse: true,
                name: "Logs",
                icon: "fas fa-code text-primary",
                state: "mapsCollapse",
                views: [
                  {
                    path: "/dashboard/partner_logs",
                    name: "Partner Logs",
                  },
                  {
                    path: "/dashboard/integration_logs",
                    name: "Integration Logs",
                  },
                  {
                    path: "/dashboard/payment_logs",
                    name: "Payments",
                  },
                  {
                    path: "/dashboard/triggers",
                    name: "Triggers",
                  },
                  {
                    path: "/dashboard/dev-info",
                    name: "Dev Info",
                  }
                ]
              },
        
            
            { divider: 'My Account' },
        
            {
                path: "/dashboard/settings",
                name: "Profile",
                icon: "fas fa-cogs text-warning",
            },
        
            {
                path: "/auth/logout",
                name: "Logout",
                icon: "fas fa-sign-out-alt text-warning",
            },
                    
        ]
    }

    // verifies if routeName is the one active (in browser input)
    activeRoute = routeName => {
        return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    };

    // toggles collapse between opened and closed (true/false)
    toggleCollapse = () => {
        this.setState({ collapseOpen: !this.state.collapseOpen });
    };
    // closes the collapse
    closeCollapse = () => {
        this.setState({ collapseOpen: false });
    };
    // this creates the intial state of this component based on the collapse routes
    // that it gets through this.props.routes
    getCollapseStates = routes => {
        let initialState = {};
        routes.map((prop, key) => {
            if (prop.collapse) {
                initialState = {
                    [prop.state]: this.getCollapseInitialState(prop.views),
                    ...this.getCollapseStates(prop.views),
                    ...initialState
                };
            }
            return null;
        });
        return initialState;
    };
    // this verifies if any of the collapses should be default opened on a rerender of this component
    // for example, on the refresh of the page,
    // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
    getCollapseInitialState(routes) {
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
                return true;
            } else if (window.location.href.indexOf(routes[i].path) !== -1) {
                return true;
            }
        }
        return false;
    }

    // this is used on mobile devices, when a user navigates
    // the sidebar will autoclose
    closeSidenav = () => {
        if (window.innerWidth < 1000) {
            this.props.toggleSidenav();
        }
    };

    // this function creates the links and collapses that appear in the sidebar (left menu)
    createLinks = routes => {
        return routes.map((prop, key) => {

            if(prop.divider) return <li key={key} className="divider"> <h6 className="navbar-heading p-0 mb-0 text-muted">{prop.divider}</h6> </li>

            if (prop.collapse) {
                var st = {};
                st[prop["state"]] = !this.state[prop.state];
                return (
                    <NavItem key={key}>
                        <NavLink
                            href="#blank"
                            data-toggle="collapse"
                            aria-expanded={this.state[prop.state]}
                            className={classnames({ active: this.getCollapseInitialState(prop.views) })}
                            onClick={e => { e.preventDefault(); this.setState(st); }}
                        >
                            {prop.icon ? <i className={prop.icon} /> : null}
                            <span className="nav-link-text">{prop.name}</span>
                        </NavLink>
                        <Collapse isOpen={this.state[prop.state]}>
                            <Nav className="nav-sm flex-column">
                                {this.createLinks(prop.views)}
                            </Nav>
                        </Collapse>
                    </NavItem>
                );
            }

            return (
                <NavItem
                    className={this.activeRoute(prop.path)}
                    key={key}
                >
                    <NavLink
                        to={prop.path}
                        activeClassName="active"
                        onClick={this.closeSidenav}
                        tag={NavLinkRRD}
                    >
                        {prop.icon !== undefined ? (
                            <><i className={prop.icon} /> <span className="nav-link-text">{prop.name}</span></>
                        ) : (
                            prop.name
                        )}
                        {prop.counter && (
                            <span className="ml-auto">
                                <Badge style={{width: 28}} color={prop.counter < 10 ? 'success' : prop.counter < 99 ? 'warning' : 'danger'}>
                                    {prop.counter >= 99 ? '99+' : prop.counter}
                                </Badge>
                            </span>
                        )}
                    </NavLink>
                </NavItem>
            );
        });
    };

    componentDidMount = () => {
        const { routes } = this.state;
        
        this.getCollapseStates(routes);

    }
  
    render() {

        const scrollBarInner = (
            <div className="scrollbar-inner">
                <div className="sidenav-header d-flex align-items-center">

                    <NavbarBrand to="/dashboard/home" tag={Link} >
                        <img alt={"Logo"} className="navbar-brand-img" src={keys.LOGO} />
                    </NavbarBrand>

                    <div className="ml-auto">
                        <Link to="/dashboard/home">
                            <div
                                className={classnames("sidenav-toggler d-xl-block", {active: this.props.sidenavOpen })}
                                // onClick={this.props.toggleSidenav}
                            >
                            {/* <div className="sidenav-toggler-inner">
                                <i className="sidenav-toggler-line" />
                                <i className="sidenav-toggler-line" />
                                <i className="sidenav-toggler-line" />
                            </div> */}
                            </div>
                        </Link>
                    </div>

                </div>

                <div className="navbar-inner">
                    <Collapse navbar isOpen={true}>
                        <Nav navbar>{this.createLinks(this.state.routes)}</Nav>
                    </Collapse>
                </div>
            </div>
        );

        return (
            <Navbar
                className="sidenav navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white z-depth-1"
            >
                {navigator.platform.indexOf("Win") > -1 ? (
                    <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
                ) : (
                    scrollBarInner
                )}
        </Navbar>
        );
    }

}


const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(Sidebar);
