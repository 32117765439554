export function secondsToTime(e){

    const day       = 86400;
    const hour      = 3600;
    const minute    = 60;
    const second    = 1;

    if(!e || e === 'N/A') return 'N/A'

    const days = Math.floor(e / day);
    const hours = Math.floor((e - (days * day)) / hour);
    const minutes = Math.floor((e - (days * day) - (hours * hour)) / minute);
    const seconds = Math.floor((e - (days * day) - (hours * hour)  - (minutes * minute)) / second);

    if(days !== 0) return days + ' Days ' + hours + ' Hrs ' + minutes + ' Mins ';
    if(hours !== 0) return hours + ' Hrs ' + minutes + ' Mins ';
    if(minutes !== 0) return minutes + ' Mins ';
    if(seconds !== 0) return seconds + ' Seconds ';
    
    return 'N/A';
}