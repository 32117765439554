import { useCallback, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardBody, CardFooter, FormGroup, Input, Container, Row, Col } from "reactstrap";

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'
import DragAndDrop from 'components/functional/uploads/DragAndDrop';

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import _channel_partners from '_functions/channel_partners';

import Circle from 'components/markup/loading/Circle';

const ChannelPartnersUpdate = ({match}) => {

    const [err, setErr] = useState(false);
    const [redirect, setRedirect] = useState('');
    const [partner, setPartner] = useState(null);

    const onSave = useCallback(async () => {
        toggleStandardLoader(true)
        const saved = await _channel_partners.update(partner._id, partner)
        toggleStandardLoader(false)

        if(saved.success) {
            toggleAlertBS(false, 'Channel Partner Updated Successfully')
            setPartner(saved.data);
            setErr(false);
        } else {
            setErr(true)
        }
    }, [partner])

    const onSetState = useCallback((field, value) => {
        const _partner = JSON.parse(JSON.stringify(partner))
        _partner[field] = value;
        setPartner(_partner);
    }, [partner])

    const onUpload = useCallback((data) => {
        try {
            const response = JSON.parse(data.xhr.response)
            setPartner(response.data)
            toggleAlertBS(false, "Logo Uploaded Successfully")
        } catch(e) {
            toggleAlertBS(false, "An error occurred, please try again.")
        }
    }, [])

    const onSetLink = useCallback((value) => {

        // this is a hacky way of re-introducing _ as a space so it does not 
        // get filtered out by the second regex expression. Can be refactored
        // in the future but is fine for now  
        value = value.replace(/_/g, ' ')
        value = value.replace(/[^a-zA-Z0-9 ]/g, "")
        value = value.replace(/ /g, '_')
        value = value.toLowerCase();

        onSetState('custom_link', value)
    }, [onSetState])


    const fetchPartner = useCallback(async (_id) => {
        const partner = await _channel_partners.findById(_id)
        if(partner.data) {
            setPartner(partner.data)
        } else {
            setRedirect('/dashboard/channel_partners')
        }
    }, [])

    useEffect(() => {
        fetchPartner(match.params.channel_partner_id)
    }, [match.params.channel_partner_id, fetchPartner])

    if(redirect) return <Redirect to={redirect} />
    if(!partner) return <div className="py-6"><Circle /></div>

    return (
        <Container fluid>
    
            <Helmet>
                <title>{`All Contacts`}</title>
                <meta name="description" content="Contacts" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Channel Partners</span>}
                breadcrumb_1={'Update'}
                icon="fas fa-handshake"
                actionComponent={(
                    <Link to={`/dashboard/channel_partners`} className="btn btn-outline-warning">
                        <i className="fas fa-arrow-left " /> Back
                    </Link>
                )}
            />
    
            <Card className="card-color card-primary table-fixed">
                <CardHeader>
                    <CardTitle className="mb-0">Update A Channel Partner</CardTitle>
                </CardHeader>
                <CardBody>
                    <FormGroup>
                        <label className="form-control-label">Channel Partner Name</label>
                        <Input 
                            type="text"
                            onChange={(e) => onSetState('name', e.target.value)}
                            value={partner.name}
                        />
                    </FormGroup>
                    <FormGroup>
                        <label className="form-control-label">Channel Partner Percent (0 - 100)</label>
                        <Input 
                            type="number"
                            onChange={(e) => onSetState('percent', e.target.value)}
                            value={partner.percent}
                        />
                    </FormGroup>
                    <FormGroup>
                        <label className="form-control-label">Custom URL</label>
                        <Input 
                            type="text"
                            onChange={(e) => onSetLink(e.target.value)}
                            value={partner.custom_link}
                        />
                    </FormGroup>
                    <FormGroup>
                        <label className="form-control-label">Internal Tracking Only (Will not display "In Partnership With" on registration page)</label>
                        <Input 
                            type="select"
                            onChange={(e) => onSetState('internal', e.target.value === 'true' ? true : false)}
                            value={partner.internal === true || partner.internal === 'true' ? true : false}
                        >
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </Input>
                    </FormGroup>
                </CardBody>

                {err ? (
                    <CardFooter>
                        <p className="text-sm text-danger mb-0">Please try again, make sure you have entered a valid name and the percent of revenue share is between 0 and 100</p>
                    </CardFooter>
                ) : null}

                <CardFooter className="text-right">
                    <button className="btn btn-success" onClick={onSave}>
                        <i className="fas fa-save mr-2 " /> Update
                    </button>
                </CardFooter>

            </Card>

            <Card>
                <CardBody>
                    <Row>
                        <Col lg={6} className="align-self-center">
                            <p className="text-sm mb-3">Upload Your Company Logo</p>
                        </Col>
                        <Col lg={6} className="align-self-center text-left text-sm-right">
                            {partner.logo ? <img src={partner.logo} alt="..." style={{maxWidth: 200}} className="mb-3 rounded z-depth-2 p-3" /> : null}
                        </Col>
                    </Row>

                    <DragAndDrop
                        zone_id="documents_1"
                        url={`/v1/admin/channel_partners/${partner._id}/logo`}
                        onError={() => toggleAlertBS(true, "Please try again")}
                        resizePixels={400}
                        onUpload={onUpload}
                        defaultValue="Logo" 
                        // extraData={{company_id: company._id}}
                    />

                </CardBody>
            </Card>

        </Container>
    )

}

export default ChannelPartnersUpdate;