import { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Modal, FormGroup, Input } from "reactstrap";

import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import moment from 'moment';

import _documents from '_functions/documents'

const ExtendDownloadTime = ({showModal, toggleModal, doc, onUpdate}) => {

    const [timestamp, setTimestamp] = useState(moment().format('YYYY-MM-DD'));

    const onSave = useCallback(async () => {
        const company_id = doc.company && doc.company._id ? doc.company._id : doc.company

        toggleStandardLoader(true);
        const updated = await _documents.extendDownloadTime(company_id, doc._id, { timestamp: parseInt(moment(timestamp).endOf('day').format('X')) })
        if(updated.success) {
            if(onUpdate) onUpdate();
            toggleModal();
            toggleAlertBS(false, 'Document Updated Successfully')
        } else {
            toggleAlertBS(true, 'Please Try Again')
        }
        toggleStandardLoader(false);

    }, [timestamp, doc, onUpdate, toggleModal])

    useEffect(() => {
        if(!showModal) setTimestamp(moment().format('YYYY-MM-DD'))
    }, [showModal])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Extend Document {doc.name}</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                <FormGroup>
                    <label className="form-control-label">Can Download Until*</label>
                    <Input 
                        type="date"
                        value={timestamp}
                        onChange={(e) =>  setTimestamp(e.target.value)}
                    />
                </FormGroup>

            </div>

            <div className="modal-footer text-right">
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            </div>

        </Modal>

    )

}

ExtendDownloadTime.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default ExtendDownloadTime
