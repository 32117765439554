import { connect } from 'react-redux'
import { useEffect, useState, useCallback } from "react";
import { Helmet } from 'react-helmet';

import { Card, Container, Alert, Row, Col } from "reactstrap";

import Circle from 'components/markup/loading/Circle'
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'

import _analytics from '_functions/analytics'

import AnalyticsSidebar from '../_components/Sidebar'

import { formatCredits, formatMoney } from 'utils/currency';
import moment from 'moment';

const AnalyticsOverview = ({analytics_query_date, analytics_company}) => {

    const [ loading, setLoading ] = useState(false)
    const [ data, setData ] = useState(null)
    const [ err, setErr ] = useState(false)
    const [ currentSort, setCurrentSort ] = useState(null)

    const getCaret = useCallback((field) => {
        if(currentSort === field + 'ASC') {
            return  <i className="text-white fas fa-caret-down ml-1" />
        } else if(currentSort === field + 'DESC') {
            return <i className="text-white fas fa-caret-up ml-1 " />
        }

        return <i className="fas fa-caret-left text-muted ml-1" />
    }, [currentSort])

    const onSort = (field) => {
        setLoading(true);

        const newResults = JSON.parse(JSON.stringify(data.results));
        if(currentSort === field + 'ASC') {
            if(field === 'created_at' || field === 'credits') {
                newResults.sort((a, b) => {
                    const aField = a[field] ? a[field] : 0
                    const bField = b[field] ? b[field] : 0
                    return aField < bField ? -1 : 1
                });
            } else {
                newResults.sort((a, b) => a.metrics[field] < b.metrics[field] ? -1 : 1);
            }
            setCurrentSort(field + 'DESC')
        } else {
            if(field === 'created_at' || field === 'credits') {
                newResults.sort((a, b) => {
                    const aField = a[field] ? a[field] : 0
                    const bField = b[field] ? b[field] : 0
                    return aField > bField ? -1 : 1
                });
            } else {
                newResults.sort((a, b) => a.metrics[field] > b.metrics[field] ? -1 : 1);
            }
            setCurrentSort(field + 'ASC')
        }
        setTimeout(() => {
            setData({...data, results: newResults})
            setLoading(false);
        }, 250)
    }

    const fetchData = useCallback(async (_date, company) => {
        setData(null)
        const data = await _analytics.overview(`?timeframe=${_date}&company=${company}`);
        if(data.data) {
            setData(data.data)
            setCurrentSort(null)
        } else {
            setErr('true')
        }
    }, [])

    const onQuery = useCallback(() => {
        fetchData(analytics_query_date, analytics_company)
    }, [fetchData, analytics_query_date, analytics_company])

    useEffect(() => {
        if(!data) fetchData(analytics_query_date, analytics_company)
    }, [fetchData, analytics_query_date, analytics_company, data])

    if(err) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }

    return (
        <Container fluid>
    
            <Helmet>
                <title>{`Analytics`}</title>
                <meta name="description" content="Analytics" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Analytics</span>}
                breadcrumb_1={'Overview'}
                icon="fas fa-chart-pie"
            />
    
            <Card className="card-color card-primary table-fixed">

                <Row className="archk-internal-sidebar">

                    <AnalyticsSidebar 
                        onQuery={onQuery}
                        data={data}
                        show={{
                            company: true
                        }}
                    />

                    <Col className="border-left">
                      
                        {!data || loading ? (
                            <div className="py-6"><Circle /></div>
                        ) : (
                            <div className="table-responsive border-bottom">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Company</th>
                                            <th onClick={() => onSort('credits')} className="text-right cursor-pointer" style={{fontSize: 9}}>
                                                Credits{getCaret('credits')}
                                            </th>
                                            <th onClick={() => onSort('creditEvents')} className="text-right cursor-pointer" style={{fontSize: 9}}>
                                                Spent{getCaret('creditEvents')}
                                            </th>
                                            <th onClick={() => onSort('creditPurchasesUSD')} className="text-right cursor-pointer" style={{fontSize: 9}}>
                                                Purchased{getCaret('creditPurchasesUSD')}
                                            </th>
                                            <th onClick={() => onSort('contacts')} className="text-right cursor-pointer" style={{fontSize: 9}}>
                                                Contacts{getCaret('contacts')}
                                            </th>
                                            <th onClick={() => onSort('documents')} className="text-right cursor-pointer" style={{fontSize: 9}}>
                                                Documents{getCaret('documents')}
                                            </th>
                                            <th onClick={() => onSort('created_at')} className="text-right cursor-pointer" style={{fontSize: 9}}>
                                                Created{getCaret('created_at')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.results.map((company) => (
                                            <tr key={company._id}>
                                                <td style={{fontSize: 10}}>{company.name}</td>
                                                <td className="text-right" style={{fontSize: 11}}>{formatCredits(company.credits ? company.credits : 0)}</td>
                                                <td className="text-right" style={{fontSize: 11}}>{formatCredits(company.metrics.creditEvents)}</td>
                                                <td className="text-right" style={{fontSize: 11}}>{formatMoney(company.metrics.creditPurchasesUSD)}</td>
                                                <td className="text-right" style={{fontSize: 11}}>{company.metrics.contacts}</td>
                                                <td className="text-right" style={{fontSize: 11}}>{company.metrics.documents}</td>
                                                <td className="text-right" style={{fontSize: 11}}>{moment.unix(company.created_at).format('M/D/YYYY')}</td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        )}
                    </Col>

                </Row>
               
            </Card>
        </Container>
    )

}

const mapStateToProps = state => {
	return {
	    analytics_company: state.system.analytics_company,
	    analytics_query_date: state.system.analytics_query_date,
	};
};

export default connect(mapStateToProps, '')(AnalyticsOverview);