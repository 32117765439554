import { useCallback } from "react";
import { connect } from 'react-redux';
import { FormGroup, Input } from "reactstrap";

import { setAnalyticsQueryDate } from 'store/functions/system/system'
import { setAnalyticsCompany } from 'store/functions/system/system'

import moment from 'moment';
import Cookies from 'universal-cookie';

const AnalyticsSidebar = ({data, onQuery, show, analytics_query_date, analytics_company, company_list}) => {

    const day = 86400;

    const onSetDate = useCallback((date) => {
        setAnalyticsQueryDate(date)
        const cookies = new Cookies();
        cookies.set('analytics_query_date', date, { path: '/' });
    }, [])

    const getMonthOptions = useCallback(() => {
        let markup = []

        for (let i = 0; i < 36; i++) {
            const month = moment().subtract(i, 'months').startOf('month');
            markup.push(<option key={i} value={`${month.format('X')}-${month.endOf('month').format('X')}`}>{month.format('MMM - YYYY')}</option>)

        }
        return markup;
    }, [])

    const getResult = useCallback(($match) => {
        try {

            const dateFormat = 'MM/DD/YY - h:mm A'
            const start = moment.unix($match.created_at.$gte).format(dateFormat)
            const end = $match.created_at.$lt ? moment.unix($match.created_at.$lt).format(dateFormat) : moment().format(dateFormat)

            return (
                <div>
                    <p className="text-sm mb-0">{start}</p>
                    <p className="text-sm mb-0">{end}</p>
                </div>
            )

        } catch(e) {
            console.log(e)
            return 'Invalid Result Set'
        }
    }, [])

   
    return (
        <div className='col-auto' style={{width: 200}}>

            {data && data.match && data.match.$match ? (
                <div className="py-3 px-4">
                    <p className="text-sm mb-0"> Results For: </p>
                    {getResult(data.match.$match)}
                </div>
            ) : null}

            <div className="py-3 px-4 border-top">
                <FormGroup className="mb-0">
                    <label className="form-control-label">Time Frame</label>
                    <Input 
                        type="select"
                        value={analytics_query_date.toString()}
                        onChange={(e) => onSetDate(e.target.value)}
                    >
                        <option value={day * 1}>1 Day</option>
                        <option value={day * 3}>3 Days</option>
                        <option value={day * 7}>7 Days</option>
                        <option value={day * 14}>14 Days</option>
                        <option value={day * 30}>30 Days</option>
                        <option value={day * 60}>60 Days</option>
                        <option value={day * 90}>90 Days</option>
                        <option value={day * 180}>180 Days</option>
                        <option value={day * 365}>365 Days</option>
                        <option value={'MTD'}>MTD</option>
                        <option value={'LAST MONTH'}>LAST MONTH</option>
                        <option value={'LAST QUARTER'}>LAST QUARTER</option>
                        {getMonthOptions()}
                    </Input>
                </FormGroup>

                {show && show.company ? (
                    <FormGroup className="mt-3">
                        <label className="form-control-label">Select Company</label>
                        <Input 
                            type="select"
                            value={analytics_company}
                            onChange={(e) => setAnalyticsCompany(e.target.value)}
                        >
                             <option value="__FALSE__"></option>
                            {company_list.map(company => (
                                <option key={company._id} value={company._id}>{company.name}</option>
                            ))}
                        </Input>
                    </FormGroup>
                ) : null}
            </div>

            <div className="py-3 px-4 border-top">
                <button className="btn btn-outline-success btn-block" onClick={onQuery}>Analyze</button>
            </div>

        </div>
    )

}

const mapStateToProps = state => {
	return {
	    analytics_query_date: state.system.analytics_query_date,
	    analytics_company: state.system.analytics_company,
	    company_list: state.company.company_list,
	};
};

export default connect(mapStateToProps, '')(AnalyticsSidebar);