import { useEffect, useState, useCallback } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Helmet } from 'react-helmet';

import { Container } from "reactstrap";

import _contacts from '_functions/contacts';
import _documents from '_functions/documents';

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'

import Logs from './Logs';
import Overview from './Overview';
import Timeline from './Timeline';
import Properties from './Properties';
import Navigation from './Navigation';
import Connect from './Connect';
import Credits from './Credits/index';

import LoadingTable from 'components/markup/loading/Table.js'

import { getUrlParameter, setUrlParameter } from "utils/urls";

const ContactsView = ({match, selected_company}) => {

    const [ contact, setContact ] = useState({})
    const [ documents, setDocuments ] = useState([])
    const [ loading, setLoading ] = useState({})
    const [ tab, setTab ] = useState(0)
    const [ err, setErr ] = useState(false)

    const contactID = match.params.contact_id
    const isNew = contactID === 'new'

    const setContactName = (contact) => {

        try {

            const name = contact.display_name.split(' ');
            if(name[0]) contact.given_name = name[0]
            if(name[1]) contact.family_name = name[1]
            
            return contact;

        } catch(e) {
            return contact
        }

    }

    const refreshContact = useCallback(async () => {
        const contact = await _contacts.findById(contactID, true)
        
        if(contact.data) {
            const formattedContact = setContactName(contact.data)
            setContact(formattedContact)
        } else {
            setErr(true)
        }
    }, [contactID])

    const fetchDocuments = useCallback(async () => {

        if(isNew) return setLoading(false);
        
        const foundDocuments = await _documents.byContact(selected_company._id, contactID)
        
        if(foundDocuments.data) {
            setDocuments(foundDocuments.data)
        }

    }, [contactID, isNew, selected_company._id])

    const fetchContact = useCallback(async () => {

        if(isNew) return setLoading(false);

        setLoading(true)
        
        const contact = await _contacts.findById(match.params.company_id, contactID, true)
        
        if(contact.data) {
            setContact(contact.data)
        } else {
            setErr(true)
        }

        setLoading(false)
    }, [match.params.company_id, contactID, isNew])

    const onSetTab = useCallback((tab) => {
        setTab(tab);
        setUrlParameter('nav', tab)
        if(tab === 0) fetchDocuments();
    }, [fetchDocuments])


    useEffect(() => {
        fetchContact();
        fetchDocuments();

        const nav = parseInt(getUrlParameter('nav'));
        if(nav && !Number.isNaN(nav)) setTab(nav)

    }, [fetchContact, fetchDocuments])

    return (

        <Container fluid>

            <Helmet>
                <title>{isNew ? 'Create A Contact' : 'View Contact'}</title>
                <meta name="description" content="Cases Create" />
            </Helmet>


           {err ? (
               <div className="alert alert-warning my-4 mb-0">
                   <i className="fas fa-exclamation-triangle mr-2 " />
                   The contact you are looking for cannot be found.
               </div>
           ) : loading ? (
               <LoadingTable rows={10} />
           )  : (

                <>

                <DashHeaderOpen 
                    title={<span>Dashboard</span>}
                    breadcrumb_1="Contacts"
                    breadcrumb_2={isNew ? 'New' : (
                        <span className="text-dark font-weight-bold">
                            {contact.display_name ? contact.display_name : contact.email ? contact.email : contact.phone ? contact.phone : '-'}
                        </span>
                    )}
                    icon="fas fa-user"
                    actionComponent={(
                        <>
                        <Link to={`/dashboard/${selected_company._id}/contacts`} className="btn btn-outline-warning"><i className="fas fa-arrow-left mr-2 " />All Contacts</Link>
                        </>
                    )}
                />

                <Navigation 
                    tab={tab}
                    onSetTab={onSetTab}
                />

                {tab === 0 ? (
                    <Overview 
                        contact={contact}
                        documents={documents}
                    />
                ) : tab === 1 ? (
                    <Logs
                        match={match}
                        contact={contact}
                        selected_company={selected_company}
                    />
                )  : tab === 2 ? (
                    <Timeline 
                        contact={contact}
                        selected_company={selected_company}
                    />
                ) : tab === 3 ? (
                    <Connect 
                        contact={contact}
                        company={selected_company}
                        setTab={setTab}
                        refreshContact={refreshContact}
                        fetchContact={fetchContact}
                        fetchDocuments={fetchDocuments}
                    />
                ) : tab === 4 ? (
                    <Credits 
                        contact={contact}
                    />
                ) : tab === 5 ? (
                    <Properties 
                        contact={contact}
                    />
                    
                ) : null}

                </>

           )}
                
        </Container>

    );

}



const mapStateToProps = state => {
	return {
	    device: state.device,
        selected_company: state.company.selected_company,
	};
};


export default connect(mapStateToProps, '')(ContactsView);