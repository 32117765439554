import { useState, useEffect, useCallback } from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { Card, Container, CardHeader, CardTitle } from "reactstrap";

import _credit_purchases from '_functions/credit_purchases';
import { formatMoney, formatCredits } from 'utils/currency';
import moment from 'moment'

import Circle from 'components/markup/loading/Circle';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'
import DatePicker from 'react-datepicker'

const Statements = ({selected_company}) => {

    const [month, setMonth] = useState(new Date());

    const [ data, setData ] = useState(null);
    const [ err, setErr ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback(async (month) => {
        setData(null);
        const query = await _credit_purchases.analyze(selected_company._id, moment(month).toDate().toUTCString())        
        if(query.data) {
            setData(query.data)
        } else {
            setErr(true)
        }
    }, [selected_company._id])

    useEffect(() => [
        fetchData(month)
    ], [fetchData, month])

    if(err) return <div className="alert alert-danger">Something went wrong, please try again later.</div>
    if(!data) return <Circle />

    return (
        <Container fluid>
    
            <Helmet>
                <title>{`Credit Events`}</title>
                <meta name="description" content="Credit Events" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Dashboard</span>}
                breadcrumb_1="Credit Events"
                breadcrumb_2="All"
                icon="fas fa-calendar-check"
                actionComponent={(
                    <div className="statements-date-picker">
                        <DatePicker
                            selected={month}
                            onChange={setMonth}
                            dateFormat="MMM yyyy"
                            showMonthYearPicker
                            style={{textAlign: 'center'}}
                    />
                    </div>
                )}
            />

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Profit: {formatMoney(data.credit_purchases.amount - data.internal_costs.amount)} </CardTitle>
                </CardHeader>
            </Card>
    
            <Card>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th className="text-right">Amount</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>Credits Spent</td>
                                <td className="text-right">{formatCredits(data.credit_events.credits)}</td>
                            </tr>
                            <tr>
                                <td>Credit Value USD</td>
                                <td className="text-right">{formatMoney(data.credit_events.amount)}</td>
                            </tr>
                            <tr>
                                <td>Internal Costs</td>
                                <td className="text-right">{formatMoney(data.internal_costs.amount)}</td>
                            </tr>
                            <tr>
                                <td>Purchases</td>
                                <td className="text-right">{formatMoney(data.credit_purchases.amount)}</td>
                            </tr>
                    
                        </tbody>
                    </table>
                </div>

            </Card>

        </Container>
    )

}


const mapStateToProps = state => {
	return {
        selected_company: state.company.selected_company,
	};
};

export default connect(mapStateToProps, '')(Statements);