import { useEffect, useState, useCallback } from "react";

import {  Card, CardBody, CardHeader, CardTitle, Row, Col } from "reactstrap";
import moment from 'moment';


import { formatCredits } from 'utils/currency';

import _credit_events from '_functions/credit_events'


import ModalToggler from 'components/functional/modals/Toggler'
import Circle from 'components/markup/loading/Circle'

import ModalRefund from './ModalRefund'

const ContactsTimeline = ({contact}) => {

    const [creditEvents, setCreditEvents] = useState(null)
    const [err, setErr] = useState(false)

    const fetchCredits = useCallback(async () => {

        const data = await _credit_events.search(contact.company, {contact: contact._id})
        if(data.data) {
            setCreditEvents(data.data.documents)
        } else {
            setErr(`Something's not right, please try agian later`)
        }
       
    }, [contact._id, contact.company])

    useEffect(() => {
        fetchCredits();
    }, [fetchCredits])


    return (

        <Card>
            <CardHeader>
                <Row>
                    <Col md={6} className="align-self-center">
                        <CardTitle className="mb-0">Credit Events</CardTitle>
                    </Col>
                    <Col md={6} className="align-self-center text-right">
                        <ModalToggler component={ModalRefund} contact={contact} fetchCredits={fetchCredits}>
                            <button className="btn btn-danger"><i className="fas fa-plus mr-2 " /> Refund</button>
                        </ModalToggler>
                    </Col>
                </Row>
            </CardHeader>
             {err ? (
                <CardBody>
                    <p className="text-sm mb-0 text-danger">Something's not right, please try again shortly.</p>
                </CardBody>
                ) : creditEvents && creditEvents.length ? (
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th className="text-right">Credits</th>
                                </tr>
                            </thead>
                            <tbody>
                                {creditEvents.map(event => (
                                    <tr key={event._id}>
                                        <td>{moment(event.date).format('MM/DD/YYYY - h:mm A')}</td>
                                        <td className="text-capitalize">
                                            {event.type === 'yodlee_statement' || event.type === 'mx_statement' ? 'Bank Statement' : event.type.replace(/_/g, ' ')}
                                        </td>
                                        <td className="text-right">{formatCredits(event.credits)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : creditEvents ? (
                    <CardBody>
                        <p className="text-sm mb-0">No credit events have been found for this contact.</p>
                    </CardBody>
                ) : (
                    <CardBody>
                        <div className="pt-5 pb-4"><Circle /></div>
                    </CardBody>
                )}
        </Card>
  
    );

}

export default ContactsTimeline;