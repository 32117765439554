import { useState, useEffect, useCallback } from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';

import { Card, Container, Input, FormGroup, Row, Col, CardHeader, CardFooter } from "reactstrap";

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'
import Circle from 'components/markup/loading/Circle'

import _packages from '_functions/packages';
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';


const PackagesAll = ({match, SETTINGS}) => {

    const [state, setState] = useState({
        name: '',
        credits: 0,
        free_credits: 0,
        amount: 0,
        active: false,
        description: '',
        can_reload: true
    })
    const [loaded, setLoaded] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [err, setErr] = useState(false);
    const [errors, setErrors] = useState([]);

    const onSetState = (field, e) => {
        const newState = JSON.parse(JSON.stringify(state));
        const value = e.target.value;
        newState[field] = value;
        if(field === 'active' || field === 'can_reload') {
            newState[field] = value === 'yes' ? true : false
        }
        setState(newState)
    }

    const onSave = useCallback(async (state) => {

        setErr(false)

        const errors = [];

        state.amount = parseFloat(state.amount)
        state.credits = parseFloat(state.credits)
        state.free_credits = parseFloat(state.free_credits)

        if(!state.name) errors.push('name');
        if(!state.credits) errors.push('credits');
        if(!state.free_credits && state.free_credits !== 0) errors.push('free_credits');
        if(!state.amount) errors.push('amount');

        setErrors(errors);

        if(errors.length) return;

        toggleStandardLoader(true);

        let action;

        if(state._id) {
            action = await _packages.update(state._id, state);
        } else {
            action = await _packages.create(state);
        }

        if(action.success) {
            setRedirect(true);
            toggleAlertBS(false, 'Package Saved Successfully');
        } else {
            setErr(action.message)
        }

        toggleStandardLoader(false);


    }, [])

    const fetchPackage = useCallback(async () => {
        const foundPackage = await _packages.findById(match.params.package_id);
        if(foundPackage.data) {
            const p = foundPackage.data;
            p.amount = p.amount / SETTINGS.decimalMultiplier;
            p.credits = p.credits / SETTINGS.decimalMultiplier;
            p.free_credits = p.free_credits / SETTINGS.decimalMultiplier;
            setState(p);
            setLoaded(true);
        } else {
            setRedirect(true)
        }
    }, [match.params.package_id, SETTINGS.decimalMultiplier])

    useEffect(() => {
        if(match.params.package_id !== 'new') {
            fetchPackage();
        } else {
            setLoaded(true);
        }
    }, [match, fetchPackage])

    if(redirect) return <Redirect to={`/dashboard/packages/all`} />
    if(!loaded) return <Circle />

    return (
        <Container fluid>
    
            <Helmet>
                <title>{`All Packages`}</title>
                <meta name="description" content="Packages" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Billing</span>}
                breadcrumb_1="Packages"
                breadcrumb_2={state._id ? state.name : 'Add New'}
                icon="fas fa-dollar-sign"
                actionComponent={(
                    <Link to="/dashboard/packages/all" className="btn btn-outline-warning">
                        <i className="fas fa-arrow-left mr-2 " /> back
                    </Link>
                )}
            />
    
            <Card className="card-color card-primary table-fixed">
                
                <CardHeader>
                    <Row>
                        <Col lg={6}>
                            <FormGroup>
                                <label className="form-control-label">Package Name*</label>
                                <Input 
                                    type="text"
                                    value={state.name}
                                    invalid={errors.includes('name')}
                                    onChange={(e) => onSetState('name', e)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <label className="form-control-label">Credits Per Purchase*</label>
                                <Input 
                                    type="number"
                                    value={state.credits}
                                    invalid={errors.includes('credits')}
                                    onChange={(e) => onSetState('credits', e)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <label className="form-control-label">Bonus Credits*</label>
                                <Input 
                                    type="number"
                                    value={state.free_credits}
                                    invalid={errors.includes('free_credits')}
                                    onChange={(e) => onSetState('free_credits', e)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <label className="form-control-label">Cost Per Purchase (USD)*</label>
                                <Input 
                                    type="number"
                                    value={state.amount}
                                    invalid={errors.includes('amount')}
                                    onChange={(e) => onSetState('amount', e)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <label className="form-control-label">Description</label>
                                <Input 
                                    type="textarea"
                                    value={state.description}
                                    onChange={(e) => onSetState('description', e)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </CardHeader>

                <CardHeader>
                    <Row>
                        <Col lg={6}>
                           
                            <FormGroup>
                                <label className="form-control-label">Package Can Be Set As Auto Reloaded*</label>
                                <p className="text-sm mb-0">Note that changing this will not affect companies already subscribed to auto load this package.</p>
                                <Input 
                                    type="select"
                                    value={state.can_reload ? 'yes' : 'no'}
                                    onChange={(e) => onSetState('can_reload', e)}
                                >
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                           
                            <FormGroup>
                                <label className="form-control-label">Package Is Active*</label>
                                <Input 
                                    type="select"
                                    value={state.active ? 'yes' : 'no'}
                                    onChange={(e) => onSetState('active', e)}
                                >
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                </CardHeader>

                {err ? (
                    <CardFooter>
                        {err.map(e => (
                            <p className="text-sm font-weight-bold text-warning mb-0">{e}</p>
                        ))}
                    </CardFooter>
                ) : null}

                <CardFooter className="text-right">
                    <button className="btn btn-outline-success" onClick={() => onSave(state)}>
                        <i className="fas fa-save mr-2 " />
                        Save
                    </button>
                </CardFooter>

            </Card>

        </Container>
    )

}

const mapStateToProps = state => {
	return {
	    SETTINGS: state.load.SETTINGS,
	};
};

export default connect(mapStateToProps, '')(PackagesAll);