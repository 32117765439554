import { useState } from "react";
import { Helmet } from 'react-helmet';

import { Card, Container, Alert, Badge } from "reactstrap";

import Table, { downloadCSV } from 'components/functional/tables/Standard';
import NameCircle from 'components/markup/misc/NameCircle';
import React from "react";
import { Link } from 'react-router-dom';
import formatText from 'utils/formatText';
import _contacts from '_functions/contacts';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'
import moment from 'moment';

import { connect } from 'react-redux';

const ContactsAll = ({device, selected_company}) => {

    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ error, setError ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        const query = await _contacts.search(selected_company._id, { ...params, isCSV })
        
        if(query.data) {
            return resolve(query.data)
        } else {
            setError(true)
        }
    })


    const onDownloadCSV = async (params) => {
        const data = await fetchData(params, true);
        downloadCSV(data, { name: 'contacts' });
    }

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = device.info.isMD ? [
        {
            dataField: "given_name",
            text: "Name",
            formatter: (cell, row) => (
                <Link className="text-capitalize" to={`/dashboard/${selected_company._id}/contacts/${row._id}`}>
                    <NameCircle width={27} contact={row} style={{marginRight: 5}} />{' '}
                    {row.display_name}
                </Link>
            ),
        },
        {
            dataField: "email",
            text: "Contact",
            formatter: (cell, row) => (
                <div>
                {row.email && <div key={row._id}><i className="text-muted fas fa-envelope mr-2" /> {row.email}</div>}
                <div>
                    {row.phone && <div key={row.created_at} > <i className="text-muted fas fa-mobile mr-2" /> {formatText(row.phone).phone()} </div>}
                </div>
                </div>
            )
        },

        {
            dataField: "report_status",
            text: "Status",
            headerStyle: { width: 150 },
            formatter: (cell, row) => (
                row.report_status === 'null' ? '-' :
                row.report_status === 'requested'   ? <Badge color="warning" style={styles.badge}>Requested</Badge> : 
                row.report_status === 'in progress' ? <Badge color="info" style={styles.badge}>In Progress</Badge> : 
                row.report_status === 'complete'    ? <Badge color="success" style={styles.badge}>COMPLETE</Badge>
                : '-'
            )
        },
        {
            dataField: "mx_expires_at",
            text: "Expires In",
            headerStyle: { width: 90 },
            formatter: (cell, row) => {
                if((row.mx_expires_at || row.yodlee_expires_at) && row.mx_first_pulled_at) {

                    const expires = row.mx_expires_at ? row.mx_expires_at : row.yodlee_expires_at

                    const unix = Math.floor(new Date() / 1000)
                    const date1 = moment()
                    const date2 = moment.unix(expires)

                    let metric = 'Days';

                    if(expires < unix + 3600) {
                        metric = 'Minutes'
                    } else if(expires < unix + 86400) {
                        metric =  'Hours'
                    }

                    const diff = date2.diff(date1, metric)  

                    return diff + ' ' + metric;
                }
                return '-'
            }

        },
        {
            dataField: "_id",
            text: "Actions",
            headerStyle: {textAlign: 'right', width: 100},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <Link to={`/dashboard/${selected_company._id}/contacts/${row._id}`}><i className="fas fa-edit text-success mr-2"></i></Link>
                    </div>
                )
            }
        },
    ] : [
        {
            dataField: "given_name",
            text: "Name",
            formatter: (cell, row) => (
                <div>
                    {   
                        row.report_status === 'null' ? 
                            <Badge color="success" style={styles.badgeMobile}>-</Badge> :
                        row.report_status === 'requested'   ? 
                            <Badge color="warning" style={styles.badgeMobile}><i className="fas fa-paper-plane " /></Badge> : 
                        row.report_status === 'in progress' ? 
                            <Badge color="info" style={styles.badgeMobile}><i className="fas fa-hourglass " /></Badge> : 
                        row.report_status === 'complete'    ? 
                            <Badge color="success" style={styles.badgeMobile}><i className="fas fa-check " /></Badge>
                        :   <Badge color="success" style={styles.badgeMobile}>-</Badge>
                    }

                    <Link to={`/dashboard/${selected_company._id}/contacts/${row._id}`} className="text-capitalize">
                        {row.display_name}
                    </Link>
                </div>
            ),
        },
        
        {
            dataField: "_id",
            text: "Actions",
            headerStyle: {textAlign: 'right', width: 85},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <Link to={`/dashboard/${selected_company._id}/contacts/${row._id}`}><i className="fas fa-edit text-success mr-2"></i></Link>
                    </div>
                )
            }
        },
    ]

    if(error) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }

    return (
        <Container fluid>
    
            <Helmet>
                <title>{`All Contacts`}</title>
                <meta name="description" content="Contacts" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Companies</span>}
                breadcrumb_1={<Link to={`/dashboard/${selected_company._id}/view`}>{selected_company.name}</Link>}
                breadcrumb_2="Contacts"
                icon="fas fa-users"
                actionComponent={(
                    <Link to={`/dashboard/${selected_company._id}/view`} className="btn btn-outline-warning">
                        <i className="fas fa-arrow-left " /> Back
                    </Link>
                )}
            />
    
            <Card className="card-color card-primary table-fixed">
                <Table
                    placeholder="Name, Email, or Phone"
                    query={query}
                    columns={columns}
                    downloadCSV={onDownloadCSV}
                    data={tableData.data}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>


        </Container>
    )

}

const styles = {
    badge: {
        display: 'inline-block',
        width: 120
    },
    badgeMobile: {
        display: 'inline-block',
        width: 30,
        marginRight: 10
    }
}

const mapStateToProps = state => {
	return {
	    device: state.device,
        selected_company: state.company.selected_company,
	};
};

export default connect(mapStateToProps, '')(ContactsAll);
