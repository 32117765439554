import store from 'store';

export const formatCredits = function (amount) {
    
    try {

        // account for 4 decimal places
        amount = amount / store.getState().load.SETTINGS.decimalMultiplier;
        return amount;

    } catch (e) {

        console.log(e)
        return amount;
    }

};
export const formatMoney = function (amount, decimalCount = 2, decimal = ".", thousands = ",") {
    
    try {

        // account for 4 decimal places
        amount = amount / store.getState().load.SETTINGS.decimalMultiplier;

        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    
        const negativeSign = amount < 0 ? "-" : "";
    
        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
    
        return '$' + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        
    } catch (e) {

        console.log(e);
        return amount;

    }

};

export const passesDecimalCheck = (flat_fee_amount) => {

    const val = flat_fee_amount.split('.');

    if(val && val[1]) {
        
        if(val[1].length > 2) {
            return false;
        }

    }

    return true;

}