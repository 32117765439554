import { useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { Card, Container, Badge, Alert, UncontrolledDropdown, DropdownToggle,DropdownItem, DropdownMenu } from "reactstrap";

import Table, { downloadCSV } from 'components/functional/tables/Standard';
import React from "react";
import { Link } from 'react-router-dom';
import _documents from '_functions/documents';
import moment from 'moment'
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'

import { getDownloadName } from 'utils/documents'

import ImageRender from 'components/functional/images/ImageRenderer'

import ExtendDownloadTime from "views/dashboard/pages/_components/Documents/ExtendDownloadTime";

import ModalToggler from 'components/functional/modals/Toggler'

import { needsExtension } from 'utils/documents'

const DocumentsAll = ({device, selected_company}) => {

    const [ now ] = useState(Math.floor(new Date() / 1000));
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: null, total_documents: 0 })
    const [ error, setError ] = useState(false)
    const [ status, setStatus ] = useState('')

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage, status }

        const filterStatus = params.status !== undefined ? params.status : status
        const query = await _documents.search(selected_company._id, { ...params, status: filterStatus, isCSV })
        
        if(query.data) {
            return resolve(query.data)
        } else {
            setError(true)
        }
    })

    const onStatusChange = (e) => {

        e.preventDefault();
        let status = e.target.innerHTML.toLowerCase();
        let searchString = document.querySelector('.dataTables_filter input').value

        if(status === 'all') status = '';

        setStatus(status)
        query({status, searchString: searchString, page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage })
    }

    // similar to query however it does not pass in a limit or skip
    // and once the data is fetched it will trigger the browser to download
    // the newly created file
    const onDownloadCSV = async (params) => {
        const data = await fetchData(params, true);
        downloadCSV(data, { name: 'documents' });
    }

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {
            dataField: "reference_number",
            text: "#",
        },
        {
            dataField: "name",
            text: "Name",
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )

                let message = row.messages[row.messages.length -1];
                if(message && message.length > 40) message = message.slice(0,40) + '...'

                return (

                    <>
                    {row.contact && row.company ? (
                        <Link to={`/dashboard/${row.company._id}/contacts/${row.contact._id}`} style={{whiteSpace: 'pre'}}>
                            {getDownloadName(row, row.contact)}
                        </Link>
                    ) : (
                        getDownloadName(row, row.contact)
                    )}
                    
    
                    <div style={{maxWidth: 200, whiteSpace: 'pre-lne'}} className="text-sm text-muted">
                        <i className="fas fa-calendar mr-2 text-info " /> {moment.unix(row.created_at).format('MMM Do, YYYY - h:mm A')}
                    </div>
                    </>
                )
            }
        },
        {
            dataField: "status",
            text: "Status",
            headerFormatter: (cell, row) => {

                return (
                    <UncontrolledDropdown>

                        <DropdownToggle caret>{status ? status : 'Status'}</DropdownToggle>

                        <DropdownMenu>
                            <DropdownItem href="#pablo" onClick={onStatusChange}>All</DropdownItem>
                            <DropdownItem href="#pablo" onClick={onStatusChange}>Completed</DropdownItem>
                            <DropdownItem href="#pablo" onClick={onStatusChange}>Failed</DropdownItem>
                            <DropdownItem href="#pablo" onClick={onStatusChange}>In Queue</DropdownItem>
                            <DropdownItem href="#pablo" onClick={onStatusChange}>Aggregating</DropdownItem>
                            <DropdownItem href="#pablo" onClick={onStatusChange}>retrying</DropdownItem>
                        </DropdownMenu>

                  </UncontrolledDropdown>
                )
            },
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : row.status === 'failed' ? (
                    <Badge color="danger" className="tex-sm">{row.status}</Badge>
                ) : row.status === 'completed' ? (
                    <Badge color="success" className="tex-sm">{row.status}</Badge>
                ) : (
                    <Badge color="warning" className="tex-sm">{row.status}</Badge>
                )
            )
        },
        {
            dataField: "company",
            text: "Company",
            formatter: (cell, row) => (
                <div>
                    <div>{row.name === 'no data' ? '' :row.company ? row.company.name : '-'}</div>
                    <div>
                        {row.pinwheel_account ? 'pinwheel' : row.yodlee_account_id ? 'yodlee' : row.mx_account_guid ? 'mx' : '-'}
                    </div>
                </div>
            )
        },
        {
            dataField: "_id",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                console.log(row.finished_at)
                return (
                    row.name === 'no data' ? '' :
                    needsExtension(row) ? (
                        <div className="text-right">
                            <ModalToggler component={ExtendDownloadTime} doc={row}>
                                <button className="btn btn-info">Extend</button>
                            </ModalToggler>     
                        </div>
                    ) :
                    <div className="text-right">
                        {row.status === 'completed' ? (
                            <ImageRender doc={row}>
                                <button className="btn btn-outline-success btn-sm">
                                   Preview
                                </button>
                            </ImageRender>
                        ) : '-'}
                    </div>
                )
            }
        },
    ]

    if(error) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }

    return (
        <Container fluid>
    
            <Helmet>
                <title>{`All Documents`}</title>
                <meta name="description" content="Documents" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Companies</span>}
                breadcrumb_1={<Link to={`/dashboard/${selected_company._id}/view`}>{selected_company.name}</Link>}
                breadcrumb_2="Documents"
                icon="fas fa-file"
                actionComponent={(
                    <Link to={`/dashboard/${selected_company._id}/view`} className="btn btn-outline-warning">
                        <i className="fas fa-arrow-left " /> Back
                    </Link>
                )}
            />
    
            <Card className="card-color card-primary">    
                <Table
                    placeholder="Document Name"
                    downloadCSV={onDownloadCSV}
                    query={query}
                    columns={columns}
                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>
    
        </Container>
    )

}

const styles = {
    badge: {
        display: 'inline-block',
        width: 120
    },
    badgeMobile: {
        display: 'inline-block',
        width: 30,
        marginRight: 10
    }
}


const mapStateToProps = state => {
	return {
        selected_company: state.company.selected_company,
	};
};


export default connect(mapStateToProps, '')(DocumentsAll);