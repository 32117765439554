import * as actionTypes from '../../actions';
import keys from 'keys';
import store from '../../index';
import _companies from '_functions/companies';

import { getCompanyLink } from 'utils/helpers';

export const setCompany = async() => {
    return new Promise( async resolve => {

        const company_id = store.getState().auth.viewing_user.company;
        const company = await _companies.findById(company_id)

        if(company.success) {

            store.dispatch({ type: actionTypes.SET_COMPANY, payload: company.data });
            resolve({success: true});

        } else {

            resolve({success: false});

        }

    })

}

export const setCompanyList = async() => {
    return new Promise( async resolve => {

        const companies = await _companies.list()

        if(companies.success) {

            store.dispatch({ type: actionTypes.SET_COMPANY_LIST, payload: companies.data });
            resolve({success: true});

        } else {

            resolve({success: false});

        }

    })

}

export const setSelectedCompany = async(company) => {
    store.dispatch({ type: actionTypes.SET_SELECTED_COMPANY, payload: company });

    if(company && company._id) {
        const registrationData = await _companies.getRegistrationLink(company._id)

        if(registrationData.data) {
            const registrationLink = `${keys.REPORT_URL}/${getCompanyLink(company)}/registration?a=${registrationData.data.iv}&b=${registrationData.data.content}`
            store.dispatch({ type: actionTypes.SET_SELECTED_COMPANY_REGISTRATION, payload: registrationLink });
        }

    }
}