import multiDownload from 'multi-download';
import formatObject from 'utils/formatObject';
import formatText from 'utils/formatText';

export const getDownloadName = (document, contact) => {
    return formatText(formatObject(contact).name() + ' - ' + document.name ).capitalize() + '.pdf'
}

export const onDownloadDocument = (document, contact) => {

    const files = [document.url];
    const name = getDownloadName(document, contact);
    multiDownload(files, {
        rename: () => name
    });

}

export const needsExtension = (doc) => {
    const now = Math.floor(new Date() / 1000)
    if(doc.status !== 'completed') return false;
    if(doc.can_download_until > now) return false; 
    if(doc.finished_at > (now - (86400 * 30)) ) return false;
    return true;
}