import { useState } from "react";
import { Helmet } from 'react-helmet';

import { Card, Container, Alert } from "reactstrap";

import Table from 'components/functional/tables/Standard';
import moment from 'moment';
import React from "react";
import _logs from '_functions/logs';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'

const Logs = () => {

    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ error, setError ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = () => new Promise (async resolve => {
        const query = await _logs.fetch()
        if(query.data) {
            return resolve(query.data)
        } else {
            setError(true)
        }
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {
            dataField: "_id",
            text: "Request",
            formatter: (cell, row) => (
                <div>
                    <div>{moment.unix(row.created_at).format('MM/DD/YYYY h:mm A')}</div>
                    <div><b style={styles.divider}>Code:</b> {row.code}</div>
                    <div><b style={styles.divider}>File:</b> {row.file}</div>
                    <div><b style={styles.divider}>Text:</b> {row.text}</div>
                </div>
            )
        },
    ]

    if(error) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }

    return (
        <Container fluid>
    
            <Helmet>
                <title>{`Payment Logs`}</title>
                <meta name="description" content="Payment Logs" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Payment Logs</span>}
                breadcrumb_1="All"
                icon="fas fa-code"
            />
    
            <Card className="card-color card-primary table-fixed">
                <Table
                    hideSearch={true}
                    query={query}
                    columns={columns}
                    data={tableData.data}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>
        </Container>
    )

}

const styles = {
    divider: {
        display: 'inline-block',
        width: 100
    },
}

export default Logs