import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Modal, FormGroup, Input } from "reactstrap";

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import _credit_events from '_functions/credit_events';

const ModalRefund = ({showModal, toggleModal, contact, fetchCredits}) => {

    const [amount, setAmount] = useState(0);
    const [err, setErr] = useState(null);

    const onSave = useCallback(async (amount) => {

        const credits = parseFloat(amount)
        if(!credits || Number.isNaN(credits) || credits < 0) return setErr(`Amount must be greater than 0.`)

        toggleStandardLoader(true);

        const saved = await _credit_events.refund({contact: contact._id, credits})
        if(saved.success) {
            fetchCredits();
            toggleModal();
        } else {
            toggleAlertBS(true, `Something went wrong, please try again.`)
        }

        toggleStandardLoader(false);

    }, [contact._id, fetchCredits, toggleModal])

    useEffect(() => {
        if(showModal) {
            setErr(false);
            setAmount(0);
        }
    }, [showModal])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Refund {contact.display_name}</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                <p className="text-sm">Refund amount cannot be less than 0. Refunds are non reversible and will show up in the credit events for a contact. Make sure you enter the correct amount before proceeding.</p>

                <FormGroup>
                    <label className="form-control-label">Amount*</label>
                    <Input 
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                    />
                </FormGroup>

            </div>

            {err ? (
                <div className="modal-body bg-secondary">
                    <p className="text-sm mb-0 text-danger">{err}</p>
                </div>
            ) : null}

            <div className="modal-footer text-right">
                <button className="btn btn-outline-success" onClick={toggleModal}>
                    <i className="fas fa-arrow-left mr-2" />
                    Cancel
                </button>
                <button className="btn btn-danger" onClick={() => onSave(amount)}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            </div>

        </Modal>

    )

}

ModalRefund.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default ModalRefund
