/*
Documentation

this should wrap all logged in routes
runs logic to redirect users if they are not logged in

*/


import keys from 'keys';
import { useEffect, useState } from "react";
import { connect } from 'react-redux';

import socket from 'sockets';
import { LOGOUT } from 'sockets/events';
import store from 'store';


const onLogout = (data) => { 

    const { user_id } = data;
    const state = store.getState();

    const viewing_user = state.auth.viewing_user;

    // if the user who just logged out matches the logged in
    // user on the current page log them out. This means when
    // a user logs out in one tab ALL open tabs are logged out.
    if(viewing_user && viewing_user._id === user_id) {
        window.location.href = "/auth/logout"  
    }

}

const LoggedInRoute = ({viewing_user, children}) => {

    const [ loaded, setLoaded ] = useState(false) 

    useEffect(() => {

        // if use is not logged in send them to login
        if(!viewing_user) {
            return window.location.href = `/auth/login?redirect=${window.location.pathname}`
        }

        // send non staff members to normal dashboard
        if(viewing_user && !viewing_user.is_staff) {
            return window.location.href = keys.CLIENT_URL;
        }

        socket.on(LOGOUT, onLogout);

        setLoaded(true)

        return () => {
            socket.off(LOGOUT, onLogout);
        }


    }, [viewing_user])

    if(!loaded) return <div />

    return children

}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(LoggedInRoute);
