import { useCallback, useState } from "react";
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardBody, CardFooter, FormGroup, Input, Container } from "reactstrap";


import React from "react";
import { Link } from 'react-router-dom';

import _channel_partners from '_functions/channel_partners';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'


import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

const ChannelPartnersCreate = () => {

    const [err, setErr] = useState('');
    const [redirect, setRedirect] = useState('');
    const [name, setName] = useState('');
    const [percent, setPercent] = useState('')
    const [internal, setInternal] = useState(false)

    const onSave = useCallback(async () => {
        toggleStandardLoader(true)
        const saved = await _channel_partners.create({
            name, 
            percent: percent ? parseFloat(percent) : undefined,
            internal
        })
        toggleStandardLoader(false)
        
        if(saved.success) {
            toggleAlertBS(false, 'Channel Partner Created Successfully')
            setRedirect('/dashboard/channel_partners')
        } else {
            setErr(true)
        }
    }, [name, percent, internal])

    if(redirect) return <Redirect to={redirect} />

    return (
        <Container fluid>
    
            <Helmet>
                <title>{`All Contacts`}</title>
                <meta name="description" content="Contacts" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Channel Partners</span>}
                breadcrumb_1={'Create'}
                icon="fas fa-handshake"
                actionComponent={(
                    <Link to={`/dashboard/channel_partners`} className="btn btn-outline-warning">
                        <i className="fas fa-arrow-left " /> Back
                    </Link>
                )}
            />
    
            <Card className="card-color card-primary table-fixed">
                <CardHeader>
                    <CardTitle className="mb-0">Create A Channel Partner</CardTitle>
                </CardHeader>
                <CardBody>
                    <FormGroup>
                        <label className="form-control-label">Channel Partner Name*</label>
                        <Input 
                            type="text"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                        />
                    </FormGroup>
                    <FormGroup>
                        <label className="form-control-label">Channel Partner Percent (0 - 100)</label>
                        <Input 
                            type="number"
                            onChange={(e) => setPercent(e.target.value)}
                            value={percent}
                        />
                    </FormGroup>
                    <FormGroup>
                        <label className="form-control-label">Internal Tracking Only (Will not display "In Partnership With" on registration page)</label>
                        <Input 
                            type="select"
                            onChange={(e) => setInternal(e.target.value === 'true' ? true : false)}
                            value={internal === true || internal === 'true' ? true : false}
                        >
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </Input>
                    </FormGroup>
                </CardBody>

                {err ? (
                    <CardFooter>
                        <p className="text-sm text-danger mb-0">Please try again, make sure you have entered a valid name and the percent of revenue share is between 0 and 100</p>
                    </CardFooter>
                ) : null}

                <CardFooter className="text-right">
                    <button className="btn btn-success" onClick={onSave}>
                        <i className="fas fa-save mr-2 " /> Create
                    </button>
                </CardFooter>

            </Card>


        </Container>
    )

}

export default ChannelPartnersCreate;