import { useEffect, useState, useCallback } from "react";

import { Card, CardHeader, CardTitle } from "reactstrap";
import moment from 'moment';

import _failed_name_logs from '_functions/failed_name_logs'

const ContactsProperties = ({contact}) => {

    const [keys, setKeys] = useState([])
    const [failedNameLogs, setFailedNameLogs] = useState([])

    const renderKeyValue = (value) => {
        if(value === true) return 'true'
        if(value === false) return 'false'

        if(Array.isArray(value) || typeof value === 'object') {
            return (
                <span style={{whiteSpace: 'pre-line'}}>
                    <pre style={{whiteSpace: 'pre-wrap'}}>{JSON.stringify(value, null, 2)}</pre>
                </span>
            )
        }

        return value
    }

    const fetchFailedNameLogs = useCallback(async () => {
        const logs = await _failed_name_logs.findByContact(contact._id);
        if(logs.data) setFailedNameLogs(logs.data)
    }, [contact._id])

    useEffect(() => {
        fetchFailedNameLogs();
        const _contact = JSON.parse(JSON.stringify(contact))
        let _keys = Object.keys(_contact)
        _keys.sort(function(a, b){
            if(a < b) { return -1; }
            if(a > b) { return 1; }
            return 0;
        })
        setKeys(_keys)
    }, [contact, setKeys, fetchFailedNameLogs])

    if(!keys.length) return <div></div>


    return (
        <Card className="mb-0">

            <CardHeader>
                <CardTitle className="mb-0"><i className="fas fa-code mr-2 text-nfo " />  Development Properties</CardTitle>
            </CardHeader>

            <CardHeader>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Contact Property</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {keys.map((key, i) => (
                                <tr>
                                    <td className="text-sm" style={{whiteSpace: 'pre'}} key={i}>{key}</td>
                                    <td className="text-sm">
                                        {renderKeyValue(contact[key])}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>                        
            </CardHeader>

            {failedNameLogs && failedNameLogs.length ? (
                <>

                <CardHeader>
                    <CardTitle className="mb-0">Failed Name Logs</CardTitle>
                </CardHeader>

                <CardHeader>
                    {failedNameLogs.map(log => (
                        <div className="p-3 bg-secondary mb-3">
                            <p className="text-sm mb-0">{moment.unix(log.created_at).format('MMM Do, YYYY h:mm A')}</p>
                            <span style={{whiteSpace: 'pre-line'}}>
                                <pre style={{whiteSpace: 'pre-wrap'}}>{JSON.stringify(log, null, 2)}</pre>
                            </span>
                        </div>
                    ))}
                </CardHeader>
                </>
            ) : null}

        </Card>
    );

}

export default ContactsProperties;