import { useState } from "react";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { Card, Container, Alert } from "reactstrap";

import Table from 'components/functional/tables/Standard';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'

import { formatCredits } from 'utils/currency';

import _companies from '_functions/companies';


const CompaniesAll = ({device}) => {

    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ error, setError ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        const query = await _companies.search({ ...params, isCSV })
        
        if(query.data) {
            return resolve(query.data)
        } else {
            setError(true)
        }
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {
            dataField: "name",
            text: "Name",
            formatter: (cell, row) => (
                <div style={{whiteSpace: 'pre-line'}}>
                    <Link className="text-capitalize" to={`/dashboard/${row._id}/view`}>
                        {row.name}
                    </Link>
                </div>
            ),
        },

        {
            dataField: "email",
            text: "Contact",
            formatter: (cell, row) => (
                <div style={{whiteSpace: 'pre-line'}}>
                    {row.email && <div key={row._id}><i className="text-muted fas fa-envelope mr-2" /> {row.email}</div>}
                    <div>
                        {row.phone && <div key={row.created_at} > <i className="text-muted fas fa-mobile mr-2" /> {row.phone} </div>}
                    </div>
                </div>
            )
        },
        {
            dataField: "credits",
            text: "Credits",
            formatter: (cell, row) => (
                <div>{row.credits ? formatCredits(row.credits) : 0}</div>
            )
        },
        {
            dataField: "reloader_package",
            text: "Auto Reload",
            formatter: (cell, row) => (
                <div>{row.reloader_package ? <i className="fas fa-check text-success " /> : <i className="fas fa-times text-danger " />}</div>
            )
        },
        {
            dataField: "created_at",
            text: "Created",
            formatter: (cell, row) => (
                moment.unix(row.created_at).format('MM/DD/YYYY - h:mmA')
            )
        },

        {
            dataField: "_id",
            text: "Actions",
            headerStyle: {textAlign: 'right',},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <Link className="btn btn-outline-success" to={`/dashboard/${row._id}/contacts`}>View</Link>
                    </div>
                )
            }
        },
    ]

    if(error) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }

    return (
        <Container fluid>
    
            <Helmet>
                <title>{`All Companies`}</title>
                <meta name="description" content="Companies" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Dashboard</span>}
                breadcrumb_1="Companies"
                breadcrumb_2="All"
                icon="fas fa-home"
                actionComponent={(
                    <div>
                        <Link className="btn btn-success" to="/dashboard/companies/new"><i className="fas fa-plus mr-2 " /> Add New</Link>
                    </div>
                )}
            />
    
            <Card className="card-color card-primary table-fixed">
                <Table
                    placeholder="Name, Email, or Phone" 
                    query={query}
                    columns={columns}
                    data={tableData.data}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>
    
        </Container>
    )

}

const mapStateToProps = state => {
	return {
	    device: state.device,
	};
};

export default connect(mapStateToProps, '')(CompaniesAll);
