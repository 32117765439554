import apiWorker from '../apiWorker'

const companies =  {

    findByName            : (name)                  =>  apiWorker('get', `/v1/api/companies/name/${name}`, null),
    findById              : (company_id, hideErr)   =>  apiWorker('get', `/v1/admin/companies/${company_id}`, null, hideErr),
    search                : (data)                  =>  apiWorker('post', `/v1/admin/companies`, data),
    getRegistrationLink   : (company_id)            =>  apiWorker('get', `/v1/admin/companies/${company_id}/registration`),
    clearTerms            : (company_id)            =>  apiWorker('post', `/v1/admin/companies/${company_id}/clear_terms`),
    update                : (company_id, data)      =>  apiWorker('patch', `/v1/admin/companies/${company_id}`, data, true),
    list                  : ()                      =>  apiWorker('get', `/v1/admin/companies/list`, null, true),
    create                : (data)                  =>  apiWorker('post', `/v1/admin/companies/create`, data, true),

}

export default companies;