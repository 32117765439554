import { useState, useCallback } from 'react'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import keys from 'keys';

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import { Card, Container, FormGroup, Input, CardBody, CardFooter, CardHeader, CardTitle, Row, Col } from "reactstrap";

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'
import ConfirmationModal from 'components/functional/modals/Confirmation'
import DragAndDrop from 'components/functional/uploads/DragAndDrop';

import _companies from '_functions/companies'

const CompaniesCreate = () => {

    const [showModal, setShowModal] = useState(false);
    const [created, setCreated] = useState(false);
    const [err, setErr] = useState(null);

    const [company, setCompany] = useState({
        name: '',
        credits: 10,
        tier: 1,
        custom_link: '',
        accept_financial: true,
        accept_real_statements: true,
        accept_employment: true,
        accept_auto_policies: true,
        skip_approval: false,
        branding: {
            color: '',
            name_color: '',
            logo_width: '',
            hide_name: false,
        }
    });
    const [user, setUser] = useState({
        given_name: '',
        family_name: '',
        email: '',
        password: '',
    });


    const onSetUser = useCallback((field, value) => {
        const _user = JSON.parse(JSON.stringify(user));
        _user[field] = value;
        setUser(_user)
    }, [user])

    const onSetState = useCallback((field, value) => {
        const _company = JSON.parse(JSON.stringify(company));
        _company[field] = value;
        setCompany(_company)
    }, [company])

    const onBrandingChange = useCallback((field, value) => {
        const _company = JSON.parse(JSON.stringify(company));
        const _branding = _company.branding
        _branding[field] = value;
        _company.branding = _branding;
        setCompany(_company)
    }, [company])


    const onSave = useCallback(async () => {

        toggleStandardLoader(true);
        const saved = await _companies.create({company: { ...company, credits: parseInt(company.credits) }, user});
        toggleStandardLoader(false);

        if(saved.success) {
            toggleAlertBS(false, 'Company Created Successfully');
            setCreated(saved.data);
        } else {
            setErr(saved.message)
        }

    }, [company, user])

    const onClearTerms = useCallback(async () => {

        toggleStandardLoader(true);
        const saved = await _companies.clearTerms(company._id);
        toggleStandardLoader(false);

        if(saved.success) {
            toggleAlertBS(false, 'Terms Cleared Successfully');
        } else {
            setErr(saved.message)
            toggleAlertBS(true, `Something's Not Right, Please Try Again`);
        }

    }, [company])

    const onSetLink = useCallback((value) => {

        // this is a hacky way of re-introducing _ as a space so it does not 
        // get filtered out by the second regex expression. Can be refactored
        // in the future but is fine for now  
        value = value.replace(/_/g, ' ')
        value = value.replace(/[^a-zA-Z0-9 ]/g, "")
        value = value.replace(/ /g, '_')
        value = value.toLowerCase();

        const _company = JSON.parse(JSON.stringify(company));
        _company.custom_link = value;
        setCompany(_company)
    }, [company])
 
    return (
        <Container fluid>
    
            <Helmet>
                <title>{`Add Company`}</title>
                <meta name="description" content="Companies" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Companies</span>}
                breadcrumb_2="New"
                icon="fas fa-home"
                actionComponent={(
                    <>
                    <Link to={`/dashboard/`} className="btn btn-outline-warning">
                        <i className="fas fa-arrow-left " /> All Companies
                    </Link>
                    </>
                )}
            />

            <ConfirmationModal 
                prevButtonText="Cancel"
                nextButtonText="Send Code"
                showModal={showModal}
                toggleModal={() => setShowModal(false)}
                title={'Clear Terms'}
                onConfirmation={() => onClearTerms(true)}
                body={(
                    <span>Are you sure you wish to clear this companies terms acceptance? They will be prompted to confirm the terms of service again when they come back to Zap Repports.</span>
                )}
            />

            {created ? (
                <Card>
                    <CardBody>
                        <Row>
                            <Col lg={6} className="align-self-center">
                                <p className="text-sm mb-3">Upload Your Company Logo</p>
                            </Col>
                            <Col lg={6} className="align-self-center text-left text-sm-right">
                                {company.branding && company.branding.logo ? <img src={company.branding.logo} alt="..." style={{maxWidth: 200}} className="mb-3 rounded z-depth-2 p-3" /> : null}
                            </Col>
                        </Row>

                        <DragAndDrop
                            zone_id="documents_1"
                            url={`/v1/admin/${created.company._id}/documents/logo`}
                            onError={() => toggleAlertBS(true, "Please try again")}
                            resizePixels={400}
                            onUpload={() => toggleAlertBS(false, "Logo Uploaded Successfully")}
                            defaultValue="Logo" 
                            extraData={{company_id: created.company._id}}
                        />
                    </CardBody>

                    <CardFooter>
                        <p>{keys.CLIENT_URL}/auth/login/{created.company.custom_link}</p>
                        <p>{keys.REPORT_URL}/{created.company.custom_link}</p>
                        <p>Company ID: {created.company._id}</p>
                        <p>User Email: {created.user.email}</p>
                        <p>Password: {user.password}</p>
                    </CardFooter>
                </Card>
            ) : (
                <Card>
                    <CardHeader>
                        <CardTitle className="mb-0">General Info</CardTitle>
                    </CardHeader>

                    <CardHeader>
                        <FormGroup>
                            <label className="form-control-label">Company Name</label>
                            <Input 
                                type="text"
                                value={company.name}
                                onChange={(e) => onSetState('name', e.target.value)}
                            />
                        </FormGroup>
                        
                        <FormGroup>
                            <label className="form-control-label">Credits</label>
                            <Input 
                                type="text"
                                value={company.credits}
                                onChange={(e) => onSetState('credits', e.target.value)}
                            />
                        </FormGroup>
                        
                    </CardHeader>

                    <CardHeader>
                        <CardTitle className="mb-0">User</CardTitle>
                    </CardHeader>

                    <CardHeader>
                        
                        <Row>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">First Name</label>
                                    <Input 
                                        type="text"
                                        value={user.given_name}
                                        onChange={(e) => onSetUser('given_name', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Last Name</label>
                                    <Input 
                                        type="text"
                                        value={user.family_name}
                                        onChange={(e) => onSetUser('family_name', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    
                        <Row>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Email</label>
                                    <Input 
                                        type="text"
                                        value={user.email}
                                        onChange={(e) => onSetUser('email', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Password</label>
                                    <Input 
                                        type="text"
                                        value={user.password}
                                        onChange={(e) => onSetUser('password', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                    </CardHeader>

                    <CardHeader>
                        <CardTitle className="mb-0">Documents</CardTitle>
                    </CardHeader>

                    <CardHeader>
                    
                        <FormGroup>
                            <label className="form-control-label">Pricing Tier</label>
                            <Input 
                                type="select"
                                value={company.tier ? company.tier.toString() : '1'}
                                onChange={(e) => onSetState('tier', parseInt(e.target.value))}
                            >
                                <option value="1">Tier 1 - Individual $25/$25/$40 | Joint $35/$35/$60</option>
                                <option value="2">Tier 2 - Individual $15/$20/$35 | Joint $30/$30/$55</option>
                                <option value="3">Tier 3 - Individual $30/$30/$50 | Joint $40/$40/$70</option>
                            </Input>
                        </FormGroup>

                        <FormGroup>
                            <label className="form-control-label d-block">Accept Real Statements</label>
                            <Input
                                type="select"
                                onChange={(e) => onSetState('accept_real_statements', e.target.value === 'true' ? true : false)}
                                value={company.accept_real_statements === true || company.accept_real_statements === 'true' ? 'true' : false}
                            >
                                <option value="true">Order Transaction Histories AND Bank Statements.</option>
                                <option value="false">Order Transaction Histories.</option>
                            </Input>
                        </FormGroup>

                        {company.accept_real_statements ? (
                            <FormGroup>
                                <label className="form-control-label">How Far Should Statements Go Back?</label>
                                <Input
                                    type="select"
                                    onChange={(e) => onSetState('accept_real_statements_length', parseInt(e.target.value))}
                                    value={company.accept_real_statements_length}
                                >
                                    <option value="none">Do Not Order Bank Statements</option>
                                    <option value="30">Order 30 Days Of Bank Statements</option>
                                    <option value="90">Order 90 Days Of Bank Statements</option>
                                    <option value="365">Order 365 Days Of Bank Statements</option>
                                </Input>
                            </FormGroup>
                        ) : null}

                    
                        <FormGroup>
                            <label className="form-control-label d-block">Skip Approval</label>
                            <Input
                                type="select"
                                onChange={(e) => onSetState('skip_approval', e.target.value === 'true' ? true : false)}
                                value={company.skip_approval === true || company.skip_approval === 'true' ? 'true' : false}
                            >
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </Input>
                        </FormGroup>

                        <FormGroup>
                            <label className="form-control-label d-block">Custom Link</label>
                            <p className="text-sm mb-0 text-warning">Lowercase letters, number, and underscores only!</p>
                            <Input
                                type="text"
                                onChange={(e) => onSetLink(e.target.value)}
                                value={company.custom_link}>
                            </Input>
                        </FormGroup>

                        
                        <FormGroup>
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id="archk-accept-financial"
                                    type="checkbox"
                                    checked={company.accept_financial}
                                    onChange={() => onSetState('accept_financial',  !company.accept_financial)}
                                />
                                <label className="custom-control-label" htmlFor="archk-accept-financial">
                                    Allow Connection of Financial Accounts
                                </label>
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id="archk-accept-employment"
                                    type="checkbox"
                                    checked={company.accept_employment}
                                    onChange={() => onSetState('accept_employment',  !company.accept_employment)}
                                />
                                <label className="custom-control-label" htmlFor="archk-accept-employment">
                                    Allow Connection of Employment Records
                                </label>
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id="archk-accept-auto"
                                    type="checkbox"
                                    checked={company.accept_auto_policies}
                                    onChange={() => onSetState('accept_auto_policies',  !company.accept_auto_policies)}
                                />
                                <label className="custom-control-label" htmlFor="archk-accept-auto">
                                    Allow Connection of Auto Insurance Policies
                                </label>
                            </div>
                        </FormGroup>

                    </CardHeader>

                    <CardHeader>
                        <CardTitle className="mb-0">Branding</CardTitle>
                    </CardHeader>

                    <CardBody>

                        <FormGroup>
                            <label className="form-control-label">Page Background Color</label>
                            <Input 
                                type="text"
                                value={company.branding.color}
                                onChange={(e) => onBrandingChange('color', e.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <label className="form-control-label">Company Name Color</label>
                            <Input 
                                type="text"
                                value={company.branding.name_color}
                                onChange={(e) => onBrandingChange('name_color', e.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <label className="form-control-label">Logo Width (px)</label>
                            <Input 
                                type="text"
                                value={company.branding.logo_width}
                                onChange={(e) => onBrandingChange('logo_width', e.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <label className="form-control-label">Hide Company Name</label>
                            <Input 
                                type="select"
                                value={company.branding.hide_name ? 'true' : 'false'}
                                onChange={(e) => onBrandingChange('hide_name', e.target.value === 'true' ? true : false)}
                            >
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                            </Input>
                        </FormGroup>

                    </CardBody>

                    {err ? (
                        <CardFooter>
                            {err.map(e => <p className="text-danger font-weight-bold mb-0">{e}</p>)}
                        </CardFooter>
                    ) : null}

                    <CardFooter className="text-right">
                        <button onClick={onSave} className="btn btn-success"><i className="fas fa-save mr-2 " /> Save</button>
                    </CardFooter>
                </Card>
                    
            )}

            
        </Container>
    )

}

export default CompaniesCreate;
