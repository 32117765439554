import { useState } from "react";
import { Helmet } from 'react-helmet';

import { Card, Container, Alert, UncontrolledCollapse } from "reactstrap";

import Table from 'components/functional/tables/Standard';
import moment from 'moment';
import React from "react";
import _payment_logs from '_functions/payment_logs';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'

const PaymentLogs = () => {

    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ error, setError ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        const query = await _payment_logs.search({ ...params, isCSV })
        
        if(query.data) {
            return resolve(query.data)
        } else {
            setError(true)
        }
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {
            dataField: "_id",
            text: "Request",
            formatter: (cell, row) => {

                let request = row.request;
                let response = row.response;

                let url = ''

                try {
                    request = JSON.stringify(row.request, null, 2)
                    url = row.request.url
                } catch(e){}
                try {
                    response = JSON.stringify(row.response, null, 2)
                } catch(e){}

                return (
                    <div>
                        <div>{moment.unix(row.created_at).format('MM/DD/YYYY h:mm A')}</div>
                        <div><b style={styles.divider}>Company:</b> {row.company}</div>

                        <div id={`teaser-${row._id}`} className="cursor-pointer my-3" style={{whiteSpace: 'pre-wrap'}}>
                            {url}
                        </div>
                       
                        <UncontrolledCollapse toggler={`teaser-${row._id}`}>
                            <h4>Request</h4>
                            <span style={{whiteSpace: 'pre-line'}}>
                                <pre style={{whiteSpace: 'pre-wrap'}}>{request}</pre>
                            </span>
                            <h4>Response</h4>

                            <span style={{whiteSpace: 'pre-line'}}>
                                <pre style={{whiteSpace: 'pre-wrap'}}>{response}</pre>
                            </span>
                        </UncontrolledCollapse>
                    
                    </div>
                )

            },
        },
    ]

    if(error) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }

    return (
        <Container fluid>
    
            <Helmet>
                <title>{`Payment Logs`}</title>
                <meta name="description" content="Payment Logs" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Payment Logs</span>}
                breadcrumb_1="All"
                icon="fas fa-code"
            />
    
            <Card className="card-color card-primary table-fixed">
                <Table
                    placeholder="Name, Email, or Phone"
                    query={query}
                    columns={columns}
                    data={tableData.data}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>
        </Container>
    )

}

const styles = {
    divider: {
        display: 'inline-block',
        width: 100
    },
}

export default PaymentLogs