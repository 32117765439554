import { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import { Card, Container, FormGroup, Input, CardBody, CardFooter, CardHeader, CardTitle, Row, Col } from "reactstrap";

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'
import ConfirmationModal from 'components/functional/modals/Confirmation'
import A from 'components/markup/links/A'
import DragAndDrop from 'components/functional/uploads/DragAndDrop';

import _companies from '_functions/companies'

const CompaniesUpdate = ({selected_company, selected_company_registration}) => {

    const [showModal, setShowModal] = useState(false);
    const [company, setCompany] = useState(null);
    const [err, setErr] = useState(null);

    const onSetState = useCallback((field, value) => {
        const _company = JSON.parse(JSON.stringify(company));
        _company[field] = value;
        setCompany(_company)
    }, [company])

    const onBrandingChange = useCallback((field, value) => {
        const _company = JSON.parse(JSON.stringify(company));
        const _branding = _company.branding
        _branding[field] = value;
        _company.branding = _branding;
        setCompany(_company)
    }, [company])


    const onSave = useCallback(async () => {

        toggleStandardLoader(true);
        const saved = await _companies.update(company._id, company);
        toggleStandardLoader(false);

        if(saved.success) {
            toggleAlertBS(false, 'Company Updated Successfully');
        } else {
            setErr(saved.message)
            toggleAlertBS(true, `Something's Not Right, Please Try Again`);
        }

    }, [company])

    const onClearTerms = useCallback(async () => {

        toggleStandardLoader(true);
        const saved = await _companies.clearTerms(company._id);
        toggleStandardLoader(false);

        if(saved.success) {
            toggleAlertBS(false, 'Terms Cleared Successfully');
        } else {
            setErr(saved.message)
            toggleAlertBS(true, `Something's Not Right, Please Try Again`);
        }

    }, [company])

    const onSetLink = useCallback((value) => {

        // this is a hacky way of re-introducing _ as a space so it does not 
        // get filtered out by the second regex expression. Can be refactored
        // in the future but is fine for now  
        value = value.replace(/_/g, ' ')
        value = value.replace(/[^a-zA-Z0-9 ]/g, "")
        value = value.replace(/ /g, '_')
        value = value.toLowerCase();

        const _company = JSON.parse(JSON.stringify(company));
        _company.custom_link = value;
        setCompany(_company)
    }, [company])

    const onDocumentAcceptanceChange = useCallback((e) => {
        const value = e.target.value;

        let accept_financial;
        let accept_employment;

        if(value === "1") {
            accept_financial = true;
            accept_employment = true;
        } else if (value === "2") {
            accept_financial = true;
            accept_employment = false;
        } else {
            accept_financial = false;
            accept_employment = true;
        }
        const _company = JSON.parse(JSON.stringify(company));
        _company.accept_employment = accept_employment;
        _company.accept_financial = accept_financial;
        setCompany(_company)
    }, [company])

    useEffect(() => {
        const _company = JSON.parse(JSON.stringify(selected_company));
        if(!_company.branding) _company.branding = {};
        setCompany(_company)
    }, [selected_company])

    if(!company) return <div />

 
    return (
        <Container fluid>
    
            <Helmet>
                <title>{`All Companies`}</title>
                <meta name="description" content="Companies" />
            </Helmet>

            <div className="alert alert-danger">This page is not complete, do not use!</div>

            <DashHeaderOpen 
                title={<span>Companies</span>}
                breadcrumb_2={selected_company.name}
                icon="fas fa-home"
                actionComponent={(
                    <>
                    <button onClick={() => setShowModal(true)} className="btn btn-warning">Clear Terms</button>
                    <Link to={`/dashboard/${selected_company._id}/view`} className="btn btn-outline-warning">
                        <i className="fas fa-arrow-left " /> Back
                    </Link>
                    </>
                )}
            />

            <ConfirmationModal 
                prevButtonText="Cancel"
                nextButtonText="Send Code"
                showModal={showModal}
                toggleModal={() => setShowModal(false)}
                title={'Clear Terms'}
                onConfirmation={() => onClearTerms(true)}
                body={(
                    <span>Are you sure you wish to clear this companies terms acceptance? They will be prompted to confirm the terms of service again when they come back to Zap Repports.</span>
                )}
            />

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">General Info</CardTitle>
                </CardHeader>
                <CardBody>
                    <FormGroup>
                        <label className="form-control-label">Name</label>
                        <Input 
                            type="text"
                            value={company.name}
                            onChange={(e) => onSetState('name', e.target.value)}
                        />
                    </FormGroup>

                    <FormGroup>
                        <label className="form-control-label">Pricing Tier</label>
                        <Input 
                            type="select"
                            value={company.tier.toString()}
                            onChange={(e) => onSetState('tier', parseInt(e.target.value))}
                        >
                            <option value="1">Tier 1 - Individual $25/$25/$40 | Joint $35/$35/$60</option>
                            <option value="2">Tier 2 - Individual $15/$20/$35 | Joint $30/$30/$55</option>
                            <option value="3">Tier 3 - Individual $30/$30/$50 | Joint $40/$40/$70</option>
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <label className="form-control-label d-block">
                            Allowed Connections

                            <span className="float-right pr-4">
                                {company.accept_financial ? (
                                    <i className="fas fa-dollar-sign text-info ml-4 mr--3" />
                                ) : null}
                                {company.accept_employment ? (
                                    <i className="fas fa-clipboard-list text-success ml-4 mr--3" />
                                ) : null}
                            </span>
                        </label>
                        <Input
                            type="select"
                            onChange={onDocumentAcceptanceChange}
                            value={
                                company.accept_financial && company.accept_employment ? '1' :
                                company.accept_financial ? '2' :
                                '3'
                            }
                        >
                            <option value="1">Allow Connection of Financial AND Employer accounts.</option>
                            <option value="2">Allow Connection of Financial accounts.</option>
                            <option value="3">Allow Connection of Employer accounts.</option>
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <label className="form-control-label d-block">Accept Real Statements</label>
                        <Input
                            type="select"
                            onChange={(e) => onSetState('accept_real_statements', e.target.value === 'true' ? true : false)}
                            value={company.accept_real_statements === true || company.accept_real_statements === 'true' ? 'true' : false}
                        >
                            <option value="true">Order Transaction Histories AND Bank Statements.</option>
                            <option value="false">Order Transaction Histories.</option>
                        </Input>
                    </FormGroup>

                    {company.accept_real_statements ? (
                        <FormGroup>
                            <label className="form-control-label">How Far Should Statements Go Back?</label>
                            <Input
                                type="select"
                                onChange={(e) => onSetState('accept_real_statements_length', parseInt(e.target.value))}
                                value={company.accept_real_statements_length}
                            >
                                <option value="none">Do Not Order Bank Statements</option>
                                <option value="30">Order 30 Days Of Bank Statements</option>
                                <option value="90">Order 90 Days Of Bank Statements</option>
                                <option value="365">Order 365 Days Of Bank Statements</option>
                            </Input>
                        </FormGroup>
                    ) : null}

                    <FormGroup>
                        <label className="form-control-label d-block">Contacts Can Change Pay Type</label>
                        <Input
                            type="select"
                            onChange={(e) => onSetState('can_change_pay_type', e.target.value === 'true' ? true : false)}
                            value={company.can_change_pay_type === true || company.can_change_pay_type === 'true' ? 'true' : false}
                        >
                            <option value="true">True</option>
                            <option value="false">False</option>
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <label className="form-control-label d-block">Contacts Can Register</label>
                        <Input
                            type="select"
                            onChange={(e) => onSetState('client_registration', e.target.value === 'true' ? true : false)}
                            value={company.client_registration === true || company.client_registration === 'true' ? 'true' : false}
                            >
                                <option value="true">True</option>
                                <option value="false">False</option>
                        </Input>
                    </FormGroup>

                    {company.client_registration ? (
                        <div className="p-3 bg-secondary">
                            <p className="text-sm mb-0 text-dark">Registration Link:</p>
                            <p className="text-sm mb-0">
                                <A href={{selected_company_registration}}>{selected_company_registration}</A>
                            </p>
                        </div>
                    ): null}

                    <FormGroup>
                        <label className="form-control-label d-block">Contact Pays</label>
                        <Input
                            type="select"
                            onChange={(e) => onSetState('contact_pays', e.target.value === 'true' ? true : false)}
                            value={company.contact_pays === true || company.contact_pays === 'true' ? 'true' : false}
                        >
                            <option value="true">True</option>
                            <option value="false">False</option>
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <label className="form-control-label d-block">Skip Approval</label>
                        <Input
                            type="select"
                            onChange={(e) => onSetState('skip_approval', e.target.value === 'true' ? true : false)}
                            value={company.skip_approval === true || company.skip_approval === 'true' ? 'true' : false}
                        >
                            <option value="true">True</option>
                            <option value="false">False</option>
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <label className="form-control-label d-block">Custom Link</label>
                        <p className="text-sm mb-0 text-warning">Lowercase letters, number, and underscores only!</p>
                        <Input
                            type="text"
                            onChange={(e) => onSetLink(e.target.value)}
                            value={company.custom_link}>
                        </Input>
                    </FormGroup>

                </CardBody>

            </Card>

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Branding</CardTitle>
                </CardHeader>

                <CardBody>

                    <Row>
                        <Col lg={6} className="align-self-center">
                            <p className="text-sm mb-3">Upload Your Company Logo</p>
                        </Col>
                        <Col lg={6} className="align-self-center text-left text-sm-right">
                            {company.branding.logo ? <img src={company.branding.logo} alt="..." style={{maxWidth: 200}} className="mb-3 rounded z-depth-2 p-3" /> : null}
                        </Col>
                    </Row>

                    <DragAndDrop
                        zone_id="documents_1"
                        url={`/v1/admin/${company._id}/documents/logo`}
                        onError={() => toggleAlertBS(true, "Please try again")}
                        resizePixels={400}
                        onUpload={() => toggleAlertBS(false, "Logo Uploaded Successfully")}
                        defaultValue="Profile Picture" 
                        extraData={{company_id: company._id}}
                    />

                    <hr />

                    <FormGroup>
                        <label className="form-control-label">Page Background Color</label>
                        <Input 
                            type="text"
                            value={company.branding.color}
                            onChange={(e) => onBrandingChange('color', e.target.value)}
                        />
                    </FormGroup>

                    <FormGroup>
                        <label className="form-control-label">Company Name Color</label>
                        <Input 
                            type="text"
                            value={company.branding.name_color}
                            onChange={(e) => onBrandingChange('name_color', e.target.value)}
                        />
                    </FormGroup>

                    <FormGroup>
                        <label className="form-control-label">Logo Width (px)</label>
                        <Input 
                            type="text"
                            value={company.branding.logo_width}
                            onChange={(e) => onBrandingChange('logo_width', e.target.value)}
                        />
                    </FormGroup>

                    <FormGroup>
                        <label className="form-control-label">Hide Company Name</label>
                        <Input 
                            type="select"
                            value={company.branding.hide_name ? 'true' : 'false'}
                            onChange={(e) => onBrandingChange('hide_name', e.target.value === 'true' ? true : false)}
                        >
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </Input>
                    </FormGroup>

                </CardBody>

                {err ? (
                    <CardFooter>
                        {err.map(e => <p className="text-danger font-weight-bold mb-0">{e}</p>)}
                    </CardFooter>
                ) : null}

                <CardFooter className="text-right">
                    <button onClick={onSave} className="btn btn-success"><i className="fas fa-save mr-2 " /> Save</button>
                </CardFooter>
            </Card>
                    
        </Container>
    )

}

const mapStateToProps = state => {
	return {
        selected_company: state.company.selected_company,
        selected_company_registration: state.company.selected_company_registration,
	};
};

export default connect(mapStateToProps, '')(CompaniesUpdate);
