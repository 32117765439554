import React from "react";
import { connect } from 'react-redux';
import {  Redirect, Route, Switch } from "react-router-dom";

import Footer from "./components/Footer";
import Navbar from "./components/Navbar/index.js";
import Sidebar from "./components/Sidebar/index.js";
import routes from "./routes";

import { Container } from 'reactstrap';
import { setCompany, setCompanyList } from 'store/functions/company/company'
import { load } from 'store/functions/load'

import SelectedCompanyRoute from "components/system/Routing/SelectedCompanyRoute";
import NonSelectedCompanyRoute from "components/system/Routing/NonSelectedCompanyRoute";

class Dashboard extends React.Component {
    state = {
        loaded: false,
        sidenavOpen: true,
        showMobileNav: false
    };

    //get routes for this layout
    getRoutes = routes => routes().map((prop, key) => {


        return (
            <Route
                exact path={`/dashboard${prop.path}`}
                component={
                    prop.requireCompany ? (props) => (
                        <SelectedCompanyRoute {...props}>
                            {prop.component}
                        </SelectedCompanyRoute>
                    ) : !prop.requireCompany ? (props) => (
                        <NonSelectedCompanyRoute {...props}>
                            {prop.component}
                        </NonSelectedCompanyRoute>
                    ) : null
                }
                key={key}
            />
        )
    })

    toggleSidenav = e => {

        if (document.body.classList.contains("g-sidenav-pinned")) {
            document.body.classList.remove("g-sidenav-show");
            document.body.classList.remove("g-sidenav-pinned");
            document.body.classList.remove("noScroll");
        } else {
            document.body.classList.add("g-sidenav-pinned");
            document.body.classList.remove("g-sidenav-hidden");   
            document.body.classList.add("noScroll");


        }

        this.setState({ sidenavOpen: !this.state.sidenavOpen });
    };

    setScreenSize = () => {

        const { device } = this.props

        if(!device.isLG) {
            document.body.classList.remove("g-sidenav-show");
            document.body.classList.remove("g-sidenav-pinned");
            document.body.classList.remove("g-sidenav-hidden");
            this.setState({ sidenavOpen: false, showMobileNav: true });
        } else {
            document.body.classList.add("g-sidenav-pinned");
            this.setState({ sidenavOpen: true, showMobileNav: false });
        }

    }

    componentDidCatch = (e) => {

        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({redirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }

    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.setScreenSize)
    }

    componentDidMount = async () => {

        await Promise.all([
            setCompany(),
            setCompanyList(),
            load()
        ])

        this.setScreenSize()
        this.setState({loaded: true})

        window.addEventListener('resize', this.setScreenSize)

    }

    render() {

        const { redirect, loaded, sidenavOpen, showMobileNav } = this.state
        const { device, selected_company } = this.props;

        if(redirect) return <Redirect to={redirect} />;
        if(!loaded) return <div />;

        return (
            <div className="layout-sidebar layout-sidebar-right">

                <Sidebar 
                    {...this.props}
                    toggleSidenav={this.toggleSidenav}
                    sidenavOpen={sidenavOpen}
                />

                <div className={`bg-toggler ${device.currentScreenWidth < 1000 && sidenavOpen ? 'show' : null}`} onClick={this.toggleSidenav}/>

                <div className="wave" />

                <div className="main-content content-wrapper" ref="mainContent" onClick={this.closeSidenav} >

                    <Navbar {...this.props} showMobileNav={showMobileNav} toggleSidenav={this.toggleSidenav} />

                    {selected_company._id ? (
                        <Container fluid>
                            <div className="alert bg-gradient-danger text-white">CID: {selected_company._id}</div>
                        </Container>
                    ) : null }

                    <div className="dashboard-content">
                        <Switch>
                            {this.getRoutes(routes)}
                            <Redirect from="*" to="/dashboard/home" />
                        </Switch>
                    </div>

                    <Footer />

                </div>

            </div>

        );
    }
}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	    device: state.device.info,
        selected_company: state.company.selected_company,
	};
};

export default connect(mapStateToProps, '')(Dashboard);
