/*
Documentation

// any routes wrapped in this will set the state of state.company.selected_company to {}

*/

import { useEffect } from 'react';
import { connect } from 'react-redux';
import { setSelectedCompany } from "store/functions/company/company";

const NonSelectedCompanyRoute = (props) => {

    useEffect(() => {
        if(props.selected_company._id) {
            setSelectedCompany({})
        }
    }, [props.selected_company._id])

    return (
        <div className="non-selected-company-route">
            <props.children {...props} />
        </div>
    )

}

const mapStateToProps = state => {
	return {
	    selected_company: state.company.selected_company,
	};
};

export default connect(mapStateToProps, '')(NonSelectedCompanyRoute);