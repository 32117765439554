/*
Documentation

this page runs the routing for verifying a user creating mx and pinwheel reports

*/

import { useState, useCallback } from "react";

import Connect from './Connect'
import VerifyIdentity from './VerifyIdentity'
import VerifyExtended from './VerifyExtended'
import VerifyStatements from './VerifyStatements'

const ConnectMX = ({foundCompany, foundContact, refreshContact, setShowMX}) => {

    const [memberToVerify, setMemberToVerify] = useState(false);
    const [redirectAfterVerify, setRedirectAfterVerify] = useState(false);

    const [view, setView] = useState('MXConnect');

    const onSetView = useCallback((view, member, redirect) => {

        if(view === 'MXConnect' && !foundCompany.accept_financial) {
            return setView('PinwheelLink')
        } else if(view === 'MXVerifyIdentity') {
            setMemberToVerify(member)
            setRedirectAfterVerify(redirect)
        } else if(view === 'PinwheelLink' && !foundCompany.accept_employment) {
            return setView('PreConfirmation')
        }
        setView(view)

    }, [foundCompany.accept_financial, foundCompany.accept_employment])

    // message is the message off a call to fetch mx members
    const noMxUser = useCallback((message) => {
        if(message && message.error && message.error.message === "We were unable to find the record you requested.") {
            return true;
        }
        return false
    }, [])

    const memberNeedsVerification = useCallback((member) => {
        if(member.connection_status !== 'CONNECTED') return true;
        if(!foundContact.mx_members) return true;

        const foundMember = foundContact.mx_members.find(m => m.member_guid === member.guid);
        if(!foundMember) return true;

        if(foundMember.identity_status === 'null') return true;
        if(foundMember.extended_status === 'null') return true;
        if(foundCompany.accept_real_statements && foundMember.statement_status === 'null') return true;

        return false;

    }, [foundContact, foundCompany])

    if(!foundCompany._id) return <div />;

    const companyNameColor = foundCompany.branding && foundCompany.branding.name_color ? foundCompany.branding.name_color : '#525f7f'


    return (
        <>

            {view === 'MXConnect' ? (
                <Connect 
                    foundCompany={foundCompany}
                    foundContact={foundContact}
                    setView={onSetView}
                    companyNameColor={companyNameColor}
                    noMxUser={noMxUser}
                    refreshContact={refreshContact}
                    memberNeedsVerification={memberNeedsVerification}
                    setShowMX={setShowMX}
                />
            ) : view === 'MXVerifyIdentity' ? (
                <VerifyIdentity 
                    foundCompany={foundCompany}
                    foundContact={foundContact}
                    setView={onSetView}
                    companyNameColor={companyNameColor}
                    noMxUser={noMxUser}
                    memberToVerify={memberToVerify}
                    redirectAfterVerify={redirectAfterVerify}
                />
            ) : view === 'MXVerifyExtended' ? (
                <VerifyExtended 
                    foundCompany={foundCompany}
                    foundContact={foundContact}
                    setView={onSetView}
                    companyNameColor={companyNameColor}
                    noMxUser={noMxUser}
                    memberToVerify={memberToVerify}
                    redirectAfterVerify={redirectAfterVerify}
                />
            ) : view === 'MXVerifyStatements' ? (
                <VerifyStatements 
                    foundCompany={foundCompany}
                    foundContact={foundContact}
                    setView={onSetView}
                    companyNameColor={companyNameColor}
                    noMxUser={noMxUser}
                    memberToVerify={memberToVerify}
                    redirectAfterVerify={redirectAfterVerify}
                />
            )  : null}

		</>

    )
}

export default ConnectMX
